import { Send } from "@material-ui/icons";
import { useQuery } from "react-query";
import { Svix, ListResponseMessageOut } from "svix";

import * as C from "@svix/common/constants";

import { getRegion, getSvix } from "src/api";
import { SourceOut, SourcesApi } from "src/api/in";
import GettingStartedCard from "src/screens/GettingStarted/GettingStartedCard";

export default function SendRequests({ createdSource }: { createdSource?: SourceOut }) {
  const { data: messages } = useQuery<ListResponseMessageOut>(
    ["in", "sources", createdSource?.id, "messages"],
    async () => {
      const sv = await getSvix();
      const api = new SourcesApi(sv);
      const { token } = await api.getDashboard(createdSource!.id);
      // FIXME SVIX_IN: This is a hacky way of doing this.
      const appPortalSvix = new Svix(token, {
        serverUrl: C.envConfig.getServerUrl(getRegion()),
      });
      return appPortalSvix.message.list(`app_${createdSource?.id}`);
    },
    { enabled: !!createdSource, refetchInterval: 1000 }
  );
  const done = messages?.data && messages.data.length > 0;

  return (
    <GettingStartedCard
      title="Send requests to the Source URL"
      icon={<Send />}
      done={done}
    >
      Once you create a Source, copy the Ingest URL and start sending requests to it.
    </GettingStartedCard>
  );
}

import { Grid, Heading, Text, Stack } from "@chakra-ui/react";
import { useQuery } from "react-query";

import * as C from "@svix/common/constants";
import { isSSOProvider, getAuth0ProviderName } from "@svix/common/utils";
import Link from "@svix/common/widgets/Link";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import { useSvixAuth } from "src/utils";
import CustomizeCard from "./cards/CustomizeCard";
import DevEnvCard from "./cards/DevEnvCard";
import DocumentationCard from "./cards/DocumentationCard";
import EventTypesCard from "./cards/EventTypesCard";
import MembersCard from "./cards/MembersCard";
import VerifyEmail from "./cards/VerifyEmail";

export default function GettingStarted() {
  const { user } = useSvixAuth();
  const { emailVerified } = useAppSelector((state) => state.auth);
  const { data: applications } = useQuery(["application"], async () => {
    const api = await getSvix();
    return await api.application.list({ limit: 1 });
  });
  const isSSO = isSSOProvider(getAuth0ProviderName(user?.sub || ""));

  return (
    <>
      <MetaTitle path={["Getting Started"]} />
      <Heading as="h1" mt={2} size="lg" mb={8}>
        Getting Started
      </Heading>
      <Grid
        gap={8}
        gridTemplateColumns={{
          md: "minmax(0, 1fr)",
          lg: "minmax(0, 5fr) minmax(240px, 2fr)",
        }}
      >
        <Stack spacing={4}>
          {isSSO && emailVerified ? <></> : <VerifyEmail />}
          <MembersCard />
          <CustomizeCard />
          <EventTypesCard />
          <DevEnvCard />
          <DocumentationCard />
        </Stack>
        <Stack
          spacing={3}
          py={6}
          borderTop="1px solid"
          borderColor="background.modifier.border"
        >
          <Heading as="h3" size="sm">
            Not sure where to start?
          </Heading>
          <Text>
            Please check out the{" "}
            <Link href={C.docs.quickstart.index}>quickstart docs</Link> or refer to the
            complete <Link href={C.docs.apiRef}>API reference</Link> instead.
          </Text>
          {applications?.data.length === 0 && (
            <Text>
              <Link
                to={`${routeResolver.getRoute("getting-started")}?signup-step=onboarding`}
              >
                Open the onboarding wizard
              </Link>{" "}
              again to create your first application.
            </Text>
          )}
        </Stack>
      </Grid>
    </>
  );
}

import {
  Box,
  Collapse,
  Flex,
  ButtonGroup,
  Button,
  useToast,
  Tag,
  HStack,
  Tooltip,
} from "@chakra-ui/react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import InfoIcon from "@material-ui/icons/Info";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";

import Card from "@svix/common/widgets/Card";
import Form from "@svix/common/widgets/Form";
import Select from "@svix/common/widgets/form/Select";
import TextField from "@svix/common/widgets/form/TextField";
import Toggle from "@svix/common/widgets/form/Toggle";
import IconTooltip from "@svix/common/widgets/IconTooltip";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { SourceOut, SourcesApi } from "src/api/in";

export default function SourceConfiguration({ source }: { source: SourceOut }) {
  const queryClient = useQueryClient();
  const defaultValues = {
    type: source.type,
    enableAuth: source.type === "http" ? false : !!source.config.secret,
    secret: source.type === "http" ? "" : source.config.secret,
  };
  const toast = useToast();
  const formCtx = useForm({
    defaultValues,
  });
  const { watch } = formCtx;

  async function onSave(form: any) {
    const sv = await getSvix();
    const api = new SourcesApi(sv);
    try {
      await api.update(source.id, {
        ...source,
        type: form.type,
        config:
          form.type !== "http"
            ? {
                secret: watch("enableAuth") ? form.secret : undefined,
              }
            : undefined,
      });
      toast({
        title: "Configuration updated",
        status: "success",
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Error updating source",
        status: "error",
      });
    }
    formCtx.reset({}, { keepValues: true });
    queryClient.invalidateQueries(["in", "sources", source.id]);
  }

  const isSecretRequired = watch("type") !== "http" && watch("enableAuth");

  const typeLabel = (
    <IconTooltip
      tooltip="Choose which service the source is for. This determines the authentication method that will be used to validate incoming requests."
      icon={InfoIcon}
      color="text.muted"
    >
      Source type
    </IconTooltip>
  );

  return (
    <Card title={<ConfigurationCardTitle hasAuth={watch("enableAuth")} />}>
      <Form onSubmit={onSave} {...formCtx}>
        <Select label={typeLabel} control={formCtx.control} name="type">
          <option value="http">HTTP</option>
          <option value="github">Github</option>
        </Select>
        <Collapse in={watch("type") !== "http"}>
          <Box pt={3}>
            <Toggle
              label={
                <>
                  <span>Enable authentication</span>
                  <Tag colorScheme="green" ml={2}>
                    Recommended
                  </Tag>
                </>
              }
              helpText="To validate the authenticity of the incoming events."
              control={formCtx.control}
              name="enableAuth"
            />
          </Box>
        </Collapse>
        <Collapse in={isSecretRequired}>
          <TextField
            mb={4}
            label="Secret"
            control={formCtx.control}
            name="secret"
            type="password"
            required={isSecretRequired}
          />
        </Collapse>
        {formCtx.formState.isDirty && (
          <Flex justifyContent="flex-end" mt={4}>
            <ButtonGroup>
              <Button variant="outline" onClick={() => formCtx.reset(defaultValues)}>
                Cancel
              </Button>
              <SubmitButton isLoading={formCtx.formState.isSubmitting}>Save</SubmitButton>
            </ButtonGroup>
          </Flex>
        )}
      </Form>
    </Card>
  );
}

function ConfigurationCardTitle({ hasAuth }: { hasAuth: boolean }) {
  return (
    <HStack alignItems="center">
      <span>Configuration</span>
      {!hasAuth && (
        <Tooltip label="Source authentication is not enabled">
          <ErrorOutlineIcon
            style={{
              marginInlineStart: "4px",
              height: "1.2rem",
              width: "1.2rem",
              color: "var(--chakra-colors-yellow-500)",
            }}
          />
        </Tooltip>
      )}
    </HStack>
  );
}

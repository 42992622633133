import { Avatar, Divider, Flex, Heading, List, ListItem } from "@chakra-ui/react";
import { Input, TrackChanges } from "@material-ui/icons";

import Sidebar, { SidebarIcon } from "@svix/common/widgets/Sidebar";

import { routeResolver } from "../../App";
import logo from "../../logo.svg";
import { TrackedSidebarItem } from "../TrackedSidebarItem";

export const drawerWidth = 240;

interface IDrawerProps {
  mobileDrawerOpen: boolean;
  setMobileDrawerOpen: (isOpen: boolean) => void;
}

export default function Drawer(props: IDrawerProps) {
  return (
    <Sidebar {...props}>
      <Flex h="100%" flexDir="column">
        <List w="100%">
          <ListItem
            p={3}
            height="64px"
            display="flex"
            alignItems="center"
            fontWeight={500}
          >
            <Avatar src={logo} size="sm" mr={3} />

            <Heading as="div" size="sm">
              Svix Ingest
            </Heading>
          </ListItem>
        </List>
        <Divider />
        <List w="100%" p={2}>
          <TrackedSidebarItem
            to={routeResolver.getRoute("in.getting-started")}
            name="in-getting-started"
          >
            <SidebarIcon>
              <TrackChanges />
            </SidebarIcon>
            Getting Started
          </TrackedSidebarItem>
          <TrackedSidebarItem
            to={routeResolver.getRoute("in")}
            name="in-sources"
            matchRoutes={[
              { path: routeResolver.getRoute("in"), exact: true },
              { path: routeResolver.getRoute("in.sources"), exact: false },
            ]}
          >
            <SidebarIcon>
              <Input />
            </SidebarIcon>
            Sources
          </TrackedSidebarItem>
        </List>
        <Divider />
      </Flex>
    </Sidebar>
  );
}

import { Text } from "@chakra-ui/react";
import Category from "@material-ui/icons/Category";
import { useQuery } from "react-query";

import Link from "@svix/common/widgets/Link";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import GettingStartedCard from "../GettingStartedCard";

export default function EventTypesCard() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;

  const { data: eventTypes } = useQuery(
    ["environments", activeEnvId, "eventTypes"],
    async () => {
      const api = await getSvix();
      return api.eventType.list();
    }
  );

  return (
    <GettingStartedCard
      done={eventTypes?.data && eventTypes.data.length > 0}
      icon={<Category style={{ fontSize: 24 }} />}
      title="Add Event Types"
      cta={<Link to={routeResolver.getRoute("event-types")}>Go to Event Types</Link>}
    >
      <Text variant="caption">
        Configure event types so your users can select which events they are interested in
        receiving.
      </Text>
    </GettingStartedCard>
  );
}

import { ButtonGroup, Flex, IconButton, MenuDivider, MenuItem } from "@chakra-ui/react";
import { CallMade, Menu as MenuIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";

import Navbar from "@svix/common/widgets/Navbar";

import { routeResolver } from "src/App";
import AccountBarItem from "../AppBar/AccountBarItem";
import EnvSwitcher from "../AppBar/EnvSwitcher";
import ColorModeToggle from "../ColorModeToggle";

interface IAppBarProps {
  mobileDrawerOpen: boolean;
  setMobileDrawerOpen: (isOpen: boolean) => void;
}

export default function AppBar(props: IAppBarProps) {
  const handleDrawerToggle = () => {
    props.setMobileDrawerOpen(!props.mobileDrawerOpen);
  };

  return (
    <Navbar>
      <IconButton
        variant="appbar"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        display={["flex", "flex", "none"]}
      >
        <MenuIcon />
      </IconButton>
      <EnvSwitcher />
      <Flex flexGrow={1} />
      <ButtonGroup alignItems="center" justifyContent="flex-end" flexGrow={1} spacing={1}>
        <ColorModeToggle />
        <SvixInAccountBarItem />
      </ButtonGroup>
    </Navbar>
  );
}

function SvixInAccountBarItem() {
  return (
    <AccountBarItem>
      <MenuItem as={Link} to={routeResolver.getRoute("")}>
        Go to Svix Dashboard
        <CallMade style={{ marginLeft: "auto", fontSize: "1rem" }} />
      </MenuItem>
      <MenuDivider />
    </AccountBarItem>
  );
}

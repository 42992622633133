import { Svix } from "svix";
import { RequestContext, HttpMethod } from "svix/dist/openapi";

export interface ListSourceOut {
  iterator?: string;
  done: boolean;
  data: SourceOut[];
}

export interface SourceOutBase {
  id: string;
  uid?: string;
  name: string;
  type: SourceType;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  ingestUrl: string;
}

export type SourceOut =
  | (SourceOutBase & {
      type: "github";
      config: SourceAuthConfig;
    })
  | (SourceOutBase & {
      type: "http";
    });

export type IngestSourceIn = {
  name: string;
  uid?: string;
  type: SourceType;
} & (
  | {
      type: "github";
      config: SourceAuthConfig;
    }
  | {
      type: "http";
    }
);

export type SourceType = "http" | "github";

export interface SourceAuthConfig {
  secret: string;
}

export interface SourceDashboardOut {
  token: string;
  url: string;
}

export interface ListIngestLogOut {
  data: IngestLogOut[];
  iterator?: string;
  done: boolean;
}

export interface IngestLogOut {
  id: string;
  org_id: string;
  source_id: string;
  status_code: number;
  error_text: string;
  headers: Record<string, string>;
  payload: string;
  created_at: string;
  updated_at: string;
}

export class SourcesApi {
  private readonly basePath = "/ingest/api/v1/source";

  private readonly svix: Svix;

  constructor(svix: Svix) {
    this.svix = svix;
  }

  private async getReqContext(path: string, method: HttpMethod) {
    const requestContext = this.svix._configuration.baseServer.makeRequestContext(
      path,
      method
    );
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
    const authMethod = this.svix._configuration.authMethods["HTTPBearer"];
    await authMethod?.applySecurityAuthentication(requestContext);
    return requestContext;
  }

  async create(source: IngestSourceIn): Promise<SourceOut> {
    const path = this.basePath;
    const requestContext = await this.getReqContext(path, HttpMethod.POST);

    requestContext.setHeaderParam("Content-Type", "application/json");
    requestContext.setBody(JSON.stringify(source));

    return this.sendRequest(requestContext);
  }

  async delete(sourceId: string): Promise<void> {
    const path = `${this.basePath}/${sourceId}`;
    const requestContext = await this.getReqContext(path, HttpMethod.DELETE);
    return this.sendRequest(requestContext);
  }

  async get(sourceId: string): Promise<SourceOut> {
    const path = `${this.basePath}/${sourceId}`;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);
    return this.sendRequest(requestContext);
  }

  async getDashboard(sourceId: string): Promise<SourceDashboardOut> {
    const path = `${this.basePath}/${sourceId}/dashboard`;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);
    return this.sendRequest(requestContext);
  }

  async list(limit?: number, iterator?: string, order?: string): Promise<ListSourceOut> {
    const path = this.basePath;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);

    if (limit !== undefined) {
      requestContext.setQueryParam("limit", limit.toString());
    }
    if (iterator !== undefined) {
      requestContext.setQueryParam("iterator", iterator);
    }
    if (order !== undefined) {
      requestContext.setQueryParam("order", order);
    }

    return this.sendRequest(requestContext);
  }

  async update(sourceId: string, source: IngestSourceIn): Promise<SourceOut> {
    const path = `${this.basePath}/${sourceId}`;
    const requestContext = await this.getReqContext(path, HttpMethod.PUT);

    requestContext.setHeaderParam("Content-Type", "application/json");
    requestContext.setBody(JSON.stringify(source));

    return this.sendRequest(requestContext);
  }

  async getIngestLogs(
    sourceId: string,
    limit?: number,
    iterator?: string,
    order?: string
  ): Promise<ListIngestLogOut> {
    const path = `${this.basePath}/${sourceId}/log`;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);

    if (limit !== undefined) {
      requestContext.setQueryParam("limit", limit.toString());
    }
    if (iterator !== undefined) {
      requestContext.setQueryParam("iterator", iterator);
    }
    if (order !== undefined) {
      requestContext.setQueryParam("order", order);
    }

    return this.sendRequest(requestContext);
  }

  private async sendRequest(requestContext: RequestContext) {
    const response = await this.svix._configuration.httpApi
      .send(requestContext)
      .toPromise();

    const body = await response.body.text();
    const bodyJson = body.length > 0 ? JSON.parse(body) : undefined;

    if (200 <= response.httpStatusCode && response.httpStatusCode < 300) {
      return bodyJson;
    } else {
      throw bodyJson;
    }
  }
}

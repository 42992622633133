import { useState } from "react";
import { HStack, Stack, Box, Divider, Collapse } from "@chakra-ui/react";
import { Steps, Step, useSteps } from "chakra-ui-steps";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";

import Button from "@svix/common/widgets/Button";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import Select from "@svix/common/widgets/form/Select";
import TextField from "@svix/common/widgets/form/TextField";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { SourceOut, SourcesApi, SourceType } from "src/api/in";
import { routeResolver } from "src/App";
import { getSourceIngestUrl } from "../Source";
import SourceUrlCard from "../Source/SourceUrlCard";

interface SourceForm {
  name: string;
  type?: SourceType;
  secret?: string;
}

const defaultValues: SourceForm = {
  name: "",
  type: "http",
  secret: "",
};

export default function SourceCreateScreen() {
  const history = useHistory();
  const formCtx = useForm<SourceForm>({
    defaultValues,
    shouldUnregister: true,
  });
  const { watch } = formCtx;
  const [createdSource, setCreatedSource] = useState<SourceOut | undefined>(undefined);

  const { nextStep, activeStep } = useSteps({
    initialStep: 0,
  });

  async function onAddSource(_form: SourceForm) {
    const sv = await getSvix();
    const api = new SourcesApi(sv);
    const newSource = await api.create({
      name: _form.name,
      type: "http",
    });
    setCreatedSource(newSource);
    nextStep();
  }

  async function onSubmit(_form: SourceForm) {
    const sv = await getSvix();
    const api = new SourcesApi(sv);
    // FIXME: Ugly type casting, we can do better
    const updatedSource: SourceOut =
      _form.type === "http"
        ? {
            ...createdSource!,
            type: "http",
          }
        : ({
            ...createdSource!,
            type: _form.type,
            config: { secret: _form.secret || "" },
          } as SourceOut);
    await api.update(updatedSource.id, updatedSource);
    history.push(
      routeResolver.getRoute("in.sources._id", { sourceId: createdSource!.id })
    );
  }

  async function onConfigureLater() {
    formCtx.reset(defaultValues, {
      keepDirty: false,
      keepTouched: false,
    });
    history.push(
      routeResolver.getRoute("in.sources._id", { sourceId: createdSource!.id })
    );
  }

  const Step1 = (
    <Form onSubmit={onAddSource} {...formCtx}>
      <GeneralFormErrors />
      <Stack spacing={4}>
        <TextField
          control={formCtx.control}
          name="name"
          label="Name"
          autoFocus
          required
          placeholder="e.g. github-prod"
        />
        <Divider />
        <HStack spacing={4} justifyContent="flex-end">
          <Button colorScheme="gray" as={Link} to={routeResolver.getRoute("in.sources")}>
            Cancel
          </Button>
          <SubmitButton isLoading={formCtx.formState.isSubmitting}>Next</SubmitButton>
        </HStack>
      </Stack>
    </Form>
  );

  const Step2 = (
    <Form onSubmit={onSubmit} {...formCtx}>
      <GeneralFormErrors />
      <Stack spacing={4}>
        <SourceUrlCard
          ingestUrl={createdSource ? getSourceIngestUrl(createdSource) : ""}
        />
        <Select label="Source Type" control={formCtx.control} name="type">
          <option value="http">HTTP</option>
          <option value="github">Github</option>
        </Select>
        <Collapse in={watch("type") !== "http"}>
          <TextField
            label="Auth secret"
            control={formCtx.control}
            name="secret"
            type="password"
          />
          <Divider />
        </Collapse>
        <HStack spacing={4} justifyContent="flex-end">
          <Button colorScheme="gray" onClick={onConfigureLater}>
            Configure later
          </Button>
          <SubmitButton isLoading={formCtx.formState.isSubmitting}>Save</SubmitButton>
        </HStack>
      </Stack>
    </Form>
  );

  return (
    <>
      <MetaTitle path={["New Source"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("in.sources")}>
            Sources
          </BreadcrumbItem>
          <BreadcrumbItem>New Source</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>

      <Box maxW="55em">
        <Steps activeStep={activeStep} mb={6}>
          <Step label="Step 1" description="Create source" />
          <Step label="Step 2" description="Configure authentication" />
        </Steps>
        {activeStep === 0 && Step1}
        {activeStep === 1 && Step2}
      </Box>
    </>
  );
}

import { ButtonGroup, Flex, IconButton, MenuDivider, MenuItem } from "@chakra-ui/react";
import { Menu as MenuIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";

import HelpBarItem from "@svix/common/widgets/HelpBarItem";
import Navbar from "@svix/common/widgets/Navbar";

import { routeResolver } from "src/App";
import { useIsAdmin, useIsTestMode } from "src/store/selectors";
import { isEE } from "src/utils";
import AccountBarItem from "./AccountBarItem";
import EnvSwitcher from "./EnvSwitcher";
import ColorModeToggle from "../ColorModeToggle";
import DashboardSearch from "../DashboardSearch";

function SvixAccountBarItem() {
  const isAdmin = useIsAdmin();

  return (
    <AccountBarItem>
      {!isEE && (
        <>
          <MenuItem as={Link} to={routeResolver.getRoute("settings.user.account")}>
            Account
          </MenuItem>
          <MenuItem
            as={Link}
            to={routeResolver.getRoute("settings.organizationGroup.members")}
          >
            Organization Members
          </MenuItem>
          <MenuItem as={Link} to={routeResolver.getRoute("settings")}>
            Settings
          </MenuItem>
          {isAdmin && (
            <MenuItem as={Link} to={routeResolver.getRoute("billing")}>
              Plans &amp; Billing
            </MenuItem>
          )}
          <MenuDivider />
        </>
      )}
    </AccountBarItem>
  );
}

interface IAppBarProps {
  mobileDrawerOpen: boolean;
  setMobileDrawerOpen: (isOpen: boolean) => void;
}

const testModeTag = {
  colorScheme: "red",
  label: "Viewing Test Data",
};

export default function AppBar(props: IAppBarProps) {
  const isTestMode = useIsTestMode();
  const handleDrawerToggle = () => {
    props.setMobileDrawerOpen(!props.mobileDrawerOpen);
  };

  return (
    <Navbar tag={isTestMode ? testModeTag : undefined}>
      <IconButton
        variant="appbar"
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        display={["flex", "flex", "none"]}
      >
        <MenuIcon />
      </IconButton>
      <EnvSwitcher />
      <Flex flexGrow={1} />
      <ButtonGroup alignItems="center" justifyContent="flex-end" flexGrow={1} spacing={1}>
        <DashboardSearch />
        <ColorModeToggle />
        {!isEE && <HelpBarItem />}
        <SvixAccountBarItem />
      </ButtonGroup>
    </Navbar>
  );
}

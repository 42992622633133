import {
  Button,
  Divider,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
} from "@chakra-ui/react";
import { ExpandMore } from "@material-ui/icons";

import { getRegionConfig } from "src/constants";
import { useAppSelector } from "src/hooks/store";
import { getEnvTag } from "src/utils";

export interface IEnvMenuProps {
  showAll: boolean;
  selectedEnvId: string | "all";
  setSelectedEnvId: (envId: string) => void;
}

export default function EnvMenu({
  showAll,
  selectedEnvId,
  setSelectedEnvId,
}: IEnvMenuProps) {
  const envs = useAppSelector((state) => state.auth.environments);
  const selectedEnv = envs.find((env) => env.orgId === selectedEnvId);

  return (
    <Menu>
      <MenuButton as={Button} rightIcon={<ExpandMore />}>
        {(!selectedEnv || selectedEnvId === "all") && "All environments"}
        {selectedEnv && (
          <HStack>
            <Icon boxSize={4} as={getRegionConfig(selectedEnv!.region).flag} />
            <Text>{selectedEnv!.orgName}</Text>
          </HStack>
        )}
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          type="radio"
          value={selectedEnv?.orgId || "all"}
          defaultValue="all"
        >
          {showAll && (
            <>
              <MenuItemOption value="all" onClick={() => setSelectedEnvId("all")}>
                <Text fontWeight="semibold" py={1}>
                  All environments
                </Text>
              </MenuItemOption>
              <Divider my={1} />
            </>
          )}
          {envs.map((env) => (
            <MenuItemOption
              key={env.orgId}
              value={env.orgId}
              onClick={() => {
                setSelectedEnvId(env.orgId);
              }}
            >
              {env.orgName}
              <Text variant="caption" fontSize="xs" alignItems="center" display="flex">
                <Icon boxSize={4} as={getRegionConfig(env.region).flag} mr={2} />
                {getEnvTag(env)}
              </Text>
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}

import { ButtonGroup, Text } from "@chakra-ui/react";
import { EventTypeIn, EventTypeOut } from "svix";

import useUpdateMutation from "@svix/common/hooks/mutate";
import Button from "@svix/common/widgets/Button";
import Card from "@svix/common/widgets/Card";
import Form from "@svix/common/widgets/Form";
import TextAreaField from "@svix/common/widgets/form/TextArea";
import Markdown from "@svix/common/widgets/Markdown";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { useAppSelector } from "src/hooks/store";
import { useIsMemberOrAdmin } from "src/store/selectors";

interface IDescriptionProps {
  eventType: EventTypeOut;
}

export default function Description(props: IDescriptionProps) {
  const { eventType } = props;
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const isMemberOrAdmin = useIsMemberOrAdmin();
  const queryKey = ["environments", activeEnvId, "eventTypes", eventType.name];

  const { edit, cancelEdit, formCtx, mutate, isEditing, isSubmitting } =
    useUpdateMutation<EventTypeOut>(queryKey, eventType, "description", async (form) => {
      const api = await getSvix();
      return api.eventType.update(eventType.name, form as EventTypeIn);
    });

  return (
    <Form onSubmit={mutate} {...formCtx}>
      <Card
        maxH={460}
        overflowY="scroll"
        title="Description"
        cta={
          isEditing ? (
            <ButtonGroup>
              <Button colorScheme="gray" size="xs" onClick={cancelEdit}>
                Cancel
              </Button>
              <SubmitButton key="save" size="xs" isLoading={isSubmitting}>
                Save
              </SubmitButton>
            </ButtonGroup>
          ) : isMemberOrAdmin ? (
            <Button type="button" size="xs" colorScheme="gray" onClick={edit}>
              Edit
            </Button>
          ) : null
        }
      >
        {isEditing ? (
          <TextAreaField autoFocus name="description" control={formCtx.control} />
        ) : (
          <>
            {eventType.description ? (
              <Text whiteSpace="pre-wrap">
                <Markdown>{eventType.description}</Markdown>
              </Text>
            ) : (
              <Text variant="caption">No description</Text>
            )}
          </>
        )}
      </Card>
    </Form>
  );
}

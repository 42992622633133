import { Input } from "@material-ui/icons";

import Link from "@svix/common/widgets/Link";

import { SourceOut } from "src/api/in";
import { routeResolver } from "src/App";
import GettingStartedCard from "src/screens/GettingStarted/GettingStartedCard";

export default function CreateSource({ createdSource }: { createdSource?: SourceOut }) {
  const done = !!createdSource;

  const cta = done ? (
    <Link to={routeResolver.getRoute("in.sources._id", { sourceId: createdSource.id })}>
      View Source: {createdSource.name}
    </Link>
  ) : (
    <Link to={routeResolver.getRoute("in.sources.new")}>Create Source</Link>
  );

  return (
    <GettingStartedCard title="1. Create a Source" icon={<Input />} done={done} cta={cta}>
      Create a Source to get an Ingest URL to receive requests.
    </GettingStartedCard>
  );
}

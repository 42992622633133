import {
  Button,
  Flex,
  Thead,
  Tr,
  Th,
  Tbody,
  Tooltip,
  useBoolean,
} from "@chakra-ui/react";
import { Add } from "@material-ui/icons";

import usePagination from "@svix/common/hooks/pagination";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  BreadcrumbItem,
  Breadcrumbs,
  PageToolbar,
} from "@svix/common/widgets/PageToolbar";
import Table from "@svix/common/widgets/Table";

import { store } from "src/store";
import { IAuth } from "src/store/auth";
import { useIsMemberOrAdmin } from "src/store/selectors";
import AddEnvironmentModal from "./AddEnvironmentModal";
import EnvironmentRow from "./EnvironmentRow";

export default function EnvironmentsScreen() {
  const [isShowingCreateModal, setShowingCreateModal] = useBoolean();
  const isMemberOrAdmin = useIsMemberOrAdmin();

  // We don't actually support pagination for environments yet, but we use this
  // so that we can use the same Table component
  const envsPerPage = 50;
  const [pagedEnvironments, environmentsCtx] = usePagination(
    ["environments"],
    async (iterator) => {
      const environments = (store.getState().auth as IAuth).environments;
      const idx = iterator ? environments.findIndex((env) => env.orgId === iterator) : 0;
      return {
        done: idx + envsPerPage >= environments.length,
        data: environments.slice(idx, idx + envsPerPage),
        iterator: environments[idx + envsPerPage]?.orgId,
      };
    }
  );

  return (
    <>
      <MetaTitle path={["Environments"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem>Environments</BreadcrumbItem>
        </Breadcrumbs>
        <Flex flexGrow={1} />
        <Tooltip
          label={!isMemberOrAdmin && "Insufficient access for managing environments"}
          hasArrow
          shouldWrapChildren
          closeOnClick={false}
        >
          <Button
            size="sm"
            disabled={!isMemberOrAdmin}
            colorScheme="blue"
            leftIcon={<Add />}
            onClick={setShowingCreateModal.on}
          >
            New Environment
          </Button>
        </Tooltip>
      </PageToolbar>
      <Table
        data-cy="environments-table"
        size="md"
        variant="hover"
        response={pagedEnvironments}
        requestElems={environmentsCtx}
      >
        <Thead>
          <Tr>
            <Th></Th>
            <Th>Name</Th>
            <Th>ID</Th>
            <Th>Tag</Th>
          </Tr>
        </Thead>
        <Tbody>
          {pagedEnvironments?.data.map((environment) => (
            <EnvironmentRow key={environment.orgId} environment={environment} />
          ))}
        </Tbody>
      </Table>

      <AddEnvironmentModal
        isOpen={isShowingCreateModal}
        onClose={setShowingCreateModal.off}
      />
    </>
  );
}

import { ButtonGroup, Flex, Heading } from "@chakra-ui/react";

import useUpdateMutation from "@svix/common/hooks/mutate";
import Button from "@svix/common/widgets/Button";
import Form from "@svix/common/widgets/Form";
import TextField from "@svix/common/widgets/form/TextField";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { SourceOut, SourcesApi } from "src/api/in";

export default function SourceName({ source }: { source: SourceOut }) {
  const { edit, cancelEdit, formCtx, mutate, isEditing, isSubmitting } =
    useUpdateMutation<SourceOut>(
      ["in", "sources", source.id],
      source,
      "name",
      async (form) => {
        const sv = await getSvix();
        const api = new SourcesApi(sv);
        return api.update(source.id, {
          ...source,
          name: form.name ?? source.name,
        });
      }
    );

  return (
    <Form onSubmit={mutate} {...formCtx}>
      {isEditing ? (
        <Flex alignItems="center" w="100%">
          <TextField
            borderRadius="input"
            size="sm"
            autoFocus
            name="name"
            control={formCtx.control}
          />
          <ButtonGroup size="sm" ml={2}>
            <Button colorScheme="gray" onClick={cancelEdit}>
              Cancel
            </Button>
            <SubmitButton key="save" isLoading={isSubmitting}>
              Save
            </SubmitButton>
          </ButtonGroup>
        </Flex>
      ) : (
        <Flex alignItems="center" w="100%">
          <Heading as="h1" size="sm" isTruncated>
            {source.name}
          </Heading>
          <Button size="sm" colorScheme="gray" variant="outline" onClick={edit} ml={4}>
            Edit
          </Button>
        </Flex>
      )}
    </Form>
  );
}

import {
  Box,
  Flex,
  HStack,
  Image,
  SkeletonText,
  SkeletonCircle,
  Table,
  Thead,
  Text,
  Tr,
  Th,
  Tbody,
  MenuItem,
  useToast,
} from "@chakra-ui/react";
import { Add } from "@material-ui/icons";
import { useQuery, useQueryClient } from "react-query";
import { Link, useHistory } from "react-router-dom";
import { TemplateOut } from "svix";
import { TransformationTemplateApi } from "svix/dist/openapi";

import Button from "@svix/common/widgets/Button";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import TableCell from "@svix/common/widgets/TableCell";
import TableRowMenu from "@svix/common/widgets/TableRowMenu";

import { getSvix, getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import { OrganizationSettingsApi } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import { useIsMemberOrAdmin } from "src/store/selectors";
import ConnectorsEmptyState from "./EmptyState";

export default function Connectors() {
  const isMemberOrAdmin = useIsMemberOrAdmin();
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const { data: templateListResponse, isLoading } = useQuery(
    ["environments", activeEnvId, "connectors"],
    async () => {
      const svix = await getSvix();
      const api = new TransformationTemplateApi(svix._configuration);
      const templates = await api.v1TransformationTemplateList({ limit: 250 });
      return templates;
    }
  );

  const { data: orgSettings } = useQuery(
    ["environments", activeEnvId, "orgSettings"],
    async () => {
      const config = await getApiConfiguration();
      const orgSettingsApi = new OrganizationSettingsApi(config);
      return orgSettingsApi.settingsGetOrganizationSettingsGet();
    }
  );
  const transformationsEnabled = orgSettings?.enableTransformations ?? false;

  return (
    <>
      <MetaTitle path={["Connectors"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem>Connectors</BreadcrumbItem>
        </Breadcrumbs>
        <Flex flexGrow={1} />
        {isMemberOrAdmin && transformationsEnabled && (
          <Button
            colorScheme="brand"
            as={Link}
            leftIcon={<Add />}
            to={routeResolver.getRoute("connectors.create.new")}
            size="sm"
          >
            Configure Connector
          </Button>
        )}
      </PageToolbar>

      {templateListResponse?.data.length === 0 && <ConnectorsEmptyState />}

      {(isLoading || (templateListResponse?.data.length ?? 0) > 0) && (
        <Box
          overflow="hidden"
          bgColor="background.secondary"
          border="1px solid"
          borderRadius="lg"
          borderColor="background.modifier.border"
        >
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Description</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && (
                <>
                  <TableCell align="left">
                    <HStack spacing={4}>
                      <SkeletonCircle size="24px" />
                      <SkeletonText noOfLines={1} width="100%" />
                    </HStack>
                  </TableCell>
                  <TableCell>
                    <SkeletonText noOfLines={1} />
                  </TableCell>
                </>
              )}
              {templateListResponse?.data.map((template) => (
                <Tr key={template.id}>
                  <TableCell align="left">
                    <HStack>
                      <Image
                        src={template.logo}
                        alt={template.name}
                        width="24px"
                        height="24px"
                      />
                      <Text pl={1} fontWeight="semibold">
                        {template.name}
                      </Text>
                    </HStack>
                  </TableCell>
                  <TableCell align="left">{template.description}</TableCell>
                  {isMemberOrAdmin ? (
                    <ConnectorMenu template={template} />
                  ) : (
                    <TableCell />
                  )}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      )}
    </>
  );
}

function ConnectorMenu(props: { template: TemplateOut }) {
  const { template } = props;
  const history = useHistory();
  const toast = useToast();
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const queryClient = useQueryClient();

  async function archiveConnector() {
    try {
      const svix = await getSvix();
      const api = new TransformationTemplateApi(svix._configuration);
      await api.v1TransformationTemplateDelete({ transformationTemplateId: template.id });
      toast({
        status: "info",
        title: `Deleted ${template.name}`,
        duration: 3000,
      });
      queryClient.invalidateQueries(["environments", activeEnvId, "connectors"]);
    } catch (e) {
      toast({
        status: "error",
        title: `Could not delete ${template.name}. Try again later.`,
        duration: 2000,
      });
    }
  }

  return (
    <TableRowMenu>
      <MenuItem
        onClick={() =>
          history.push(
            routeResolver.getRoute("connectors.update._id", { id: template.id })
          )
        }
      >
        Edit
      </MenuItem>
      <MenuItem onClick={archiveConnector} color="text.danger">
        Archive
      </MenuItem>
    </TableRowMenu>
  );
}

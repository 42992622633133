import { ComponentProps } from "react";

import { SidebarItem } from "@svix/common/widgets/Sidebar";

import { trackEvent } from "../analytics";

export const TrackedSidebarItem = (props: ComponentProps<typeof SidebarItem>) => {
  const trackClick = () => {
    trackEvent(`Sidebar ${props.name}`);
  };

  return <SidebarItem {...props} onClick={trackClick} />;
};

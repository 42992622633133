import { ResponseContext, RequestContext, HttpFile } from '../http/http';
import * as models from '../models/all';
import { Configuration} from '../configuration'

import { ApplicationCountResponse } from '../models/ApplicationCountResponse';
import { ApplicationEventTypesResponse } from '../models/ApplicationEventTypesResponse';
import { AuditLogRecord } from '../models/AuditLogRecord';
import { AuthTokenCensoredOut } from '../models/AuthTokenCensoredOut';
import { AuthTokenExpireIn } from '../models/AuthTokenExpireIn';
import { AuthTokenIn } from '../models/AuthTokenIn';
import { AuthTokenOut } from '../models/AuthTokenOut';
import { AuthTokenUpdateIn } from '../models/AuthTokenUpdateIn';
import { Authenticator } from '../models/Authenticator';
import { BillingAlertsIn } from '../models/BillingAlertsIn';
import { BillingAlertsOut } from '../models/BillingAlertsOut';
import { BillingOut } from '../models/BillingOut';
import { BorderRadiusConfig } from '../models/BorderRadiusConfig';
import { BorderRadiusEnum } from '../models/BorderRadiusEnum';
import { CustomColorPalette } from '../models/CustomColorPalette';
import { CustomStringsOverride } from '../models/CustomStringsOverride';
import { CustomThemeOverride } from '../models/CustomThemeOverride';
import { DashboardAccessOut } from '../models/DashboardAccessOut';
import { DashboardAuditLog } from '../models/DashboardAuditLog';
import { EndpointCountResponse } from '../models/EndpointCountResponse';
import { EventCatalogOut } from '../models/EventCatalogOut';
import { EventTypeOut } from '../models/EventTypeOut';
import { Feature } from '../models/Feature';
import { FindInviteByTokenIn } from '../models/FindInviteByTokenIn';
import { FontSizeConfig } from '../models/FontSizeConfig';
import { GenericCollectionListOut } from '../models/GenericCollectionListOut';
import { GenericCollectionOut } from '../models/GenericCollectionOut';
import { HTTPValidationError } from '../models/HTTPValidationError';
import { HttpErrorOut } from '../models/HttpErrorOut';
import { InviteIn } from '../models/InviteIn';
import { InviteOut } from '../models/InviteOut';
import { InviteResponseIn } from '../models/InviteResponseIn';
import { InvoiceLineOut } from '../models/InvoiceLineOut';
import { ListResponseAuthTokenCensoredOut } from '../models/ListResponseAuthTokenCensoredOut';
import { ListResponseInviteOut } from '../models/ListResponseInviteOut';
import { ListResponseOrganizationOut } from '../models/ListResponseOrganizationOut';
import { ListResponseOrganizationOutEE } from '../models/ListResponseOrganizationOutEE';
import { ListResponseOrganizationRoleOverride } from '../models/ListResponseOrganizationRoleOverride';
import { ListResponseOrganizationUserOut } from '../models/ListResponseOrganizationUserOut';
import { ListResponsePaymentMethodOut } from '../models/ListResponsePaymentMethodOut';
import { ListResponseSearchResultOut } from '../models/ListResponseSearchResultOut';
import { ListResponseVercelProjectOut } from '../models/ListResponseVercelProjectOut';
import { LoginFinishedIn } from '../models/LoginFinishedIn';
import { LoginFinishedOut } from '../models/LoginFinishedOut';
import { MfaEnrollment } from '../models/MfaEnrollment';
import { MfaValidation } from '../models/MfaValidation';
import { OrganizationAndRole } from '../models/OrganizationAndRole';
import { OrganizationEnvironment } from '../models/OrganizationEnvironment';
import { OrganizationGroupRow } from '../models/OrganizationGroupRow';
import { OrganizationGroupSettingUpdate } from '../models/OrganizationGroupSettingUpdate';
import { OrganizationGroupSettingsOut } from '../models/OrganizationGroupSettingsOut';
import { OrganizationIdAndEnvironment } from '../models/OrganizationIdAndEnvironment';
import { OrganizationIn } from '../models/OrganizationIn';
import { OrganizationInviteStatus } from '../models/OrganizationInviteStatus';
import { OrganizationMessageStatisticsData } from '../models/OrganizationMessageStatisticsData';
import { OrganizationMessageStatisticsResponse } from '../models/OrganizationMessageStatisticsResponse';
import { OrganizationOut } from '../models/OrganizationOut';
import { OrganizationOutEE } from '../models/OrganizationOutEE';
import { OrganizationRegion } from '../models/OrganizationRegion';
import { OrganizationRole } from '../models/OrganizationRole';
import { OrganizationRoleOverride } from '../models/OrganizationRoleOverride';
import { OrganizationRoleUpdate } from '../models/OrganizationRoleUpdate';
import { OrganizationUpdate } from '../models/OrganizationUpdate';
import { OrganizationUser } from '../models/OrganizationUser';
import { OrganizationUserOut } from '../models/OrganizationUserOut';
import { OtelConfig } from '../models/OtelConfig';
import { PaymentMethodOut } from '../models/PaymentMethodOut';
import { QuestionnaireIn } from '../models/QuestionnaireIn';
import { RecoveryCode } from '../models/RecoveryCode';
import { RecoveryCodeIn } from '../models/RecoveryCodeIn';
import { SearchQueryIn } from '../models/SearchQueryIn';
import { SearchResultOut } from '../models/SearchResultOut';
import { SettingsInternalIn } from '../models/SettingsInternalIn';
import { SettingsInternalOut } from '../models/SettingsInternalOut';
import { SlackAppOut } from '../models/SlackAppOut';
import { SlackAppRegisterIn } from '../models/SlackAppRegisterIn';
import { StatisticsPeriod } from '../models/StatisticsPeriod';
import { StatsOut } from '../models/StatsOut';
import { StripeSetupIntentOut } from '../models/StripeSetupIntentOut';
import { SubscriptionEnum } from '../models/SubscriptionEnum';
import { SubscriptionOut } from '../models/SubscriptionOut';
import { SubscriptionPatchIn } from '../models/SubscriptionPatchIn';
import { UserMetadata } from '../models/UserMetadata';
import { ValidationError } from '../models/ValidationError';
import { VercelEnabledOut } from '../models/VercelEnabledOut';
import { VercelEnvVarsIn } from '../models/VercelEnvVarsIn';
import { VercelProjectOut } from '../models/VercelProjectOut';
import { VerificationCodeIn } from '../models/VerificationCodeIn';
import { ZapierIntegrationPackageOut } from '../models/ZapierIntegrationPackageOut';
import { ObservableAdminApi } from './ObservableAPI';

import { AdminApiRequestFactory, AdminApiResponseProcessor} from "../apis/AdminApi";
export class PromiseAdminApi {
    private api: ObservableAdminApi

    public constructor(
        configuration: Configuration,
        requestFactory?: AdminApiRequestFactory,
        responseProcessor?: AdminApiResponseProcessor
    ) {
        this.api = new ObservableAdminApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Get Admin Org Messages Stats
     * @param orgId 
     * @param startDate 
     * @param endDate 
     */
    public getAdminOrgMessagesStatsAdminStatsOrgMessagesGet(orgId: string, startDate?: Date, endDate?: Date, options?: Configuration): Promise<OrganizationMessageStatisticsResponse> {
        const result = this.api.getAdminOrgMessagesStatsAdminStatsOrgMessagesGet(orgId, startDate, endDate, options);
        return result.toPromise();
    }

    /**
     * Admin only way of fetching items of a generic-collection
     * Get Generic Collections
     * @param type 
     * @param limit 
     */
    public getGenericCollectionsAdminGenericCollectionTypeGet(type: string, limit?: number, options?: Configuration): Promise<GenericCollectionListOut> {
        const result = this.api.getGenericCollectionsAdminGenericCollectionTypeGet(type, limit, options);
        return result.toPromise();
    }

    /**
     * Use for internal stats
     * Stats
     */
    public statsAdminStatsGet(options?: Configuration): Promise<StatsOut> {
        const result = this.api.statsAdminStatsGet(options);
        return result.toPromise();
    }


}



import { ObservableAuditLogApi } from './ObservableAPI';

import { AuditLogApiRequestFactory, AuditLogApiResponseProcessor} from "../apis/AuditLogApi";
export class PromiseAuditLogApi {
    private api: ObservableAuditLogApi

    public constructor(
        configuration: Configuration,
        requestFactory?: AuditLogApiRequestFactory,
        responseProcessor?: AuditLogApiResponseProcessor
    ) {
        this.api = new ObservableAuditLogApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Get the audit log for an organization
     * Get Dashboard Audit Log
     * @param orgId 
     * @param before 
     * @param after 
     * @param iterator 
     * @param limit 
     */
    public getDashboardAuditLogAuditLogOrgIdGet(orgId: string, before?: Date, after?: Date, iterator?: string, limit?: number, options?: Configuration): Promise<DashboardAuditLog> {
        const result = this.api.getDashboardAuditLogAuditLogOrgIdGet(orgId, before, after, iterator, limit, options);
        return result.toPromise();
    }


}



import { ObservableAuthenticationApi } from './ObservableAPI';

import { AuthenticationApiRequestFactory, AuthenticationApiResponseProcessor} from "../apis/AuthenticationApi";
export class PromiseAuthenticationApi {
    private api: ObservableAuthenticationApi

    public constructor(
        configuration: Configuration,
        requestFactory?: AuthenticationApiRequestFactory,
        responseProcessor?: AuthenticationApiResponseProcessor
    ) {
        this.api = new ObservableAuthenticationApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Change Password
     */
    public changePasswordAuthenticationResetPasswordPost(options?: Configuration): Promise<void> {
        const result = this.api.changePasswordAuthenticationResetPasswordPost(options);
        return result.toPromise();
    }

    /**
     * Create a new API Token
     * Create Api Token
     * @param authTokenIn 
     */
    public createApiTokenAuthenticationApiTokenPost(authTokenIn: AuthTokenIn, options?: Configuration): Promise<AuthTokenOut> {
        const result = this.api.createApiTokenAuthenticationApiTokenPost(authTokenIn, options);
        return result.toPromise();
    }

    /**
     * Mark user as inactive. If the user is the last Admin on the org group, mark all orgs and org group as deleted and all other users as inactive
     * Delete User
     */
    public deleteUserAuthenticationDeleteUserPost(options?: Configuration): Promise<void> {
        const result = this.api.deleteUserAuthenticationDeleteUserPost(options);
        return result.toPromise();
    }

    /**
     * Expire the selected auth token
     * Expire Api Token
     * @param keyId 
     * @param authTokenExpireIn 
     */
    public expireApiTokenAuthenticationApiTokenKeyIdExpirePost(keyId: string, authTokenExpireIn: AuthTokenExpireIn, options?: Configuration): Promise<void> {
        const result = this.api.expireApiTokenAuthenticationApiTokenKeyIdExpirePost(keyId, authTokenExpireIn, options);
        return result.toPromise();
    }

    /**
     * Get Dashboard
     */
    public getDashboardAuthenticationGetDashboardGet(options?: Configuration): Promise<DashboardAccessOut> {
        const result = this.api.getDashboardAuthenticationGetDashboardGet(options);
        return result.toPromise();
    }

    /**
     * Get Metadata
     */
    public getMetadataAuthenticationMetadataGet(options?: Configuration): Promise<UserMetadata> {
        const result = this.api.getMetadataAuthenticationMetadataGet(options);
        return result.toPromise();
    }

    /**
     * Setup a user account or retrieve existing one. This method should be called right after login / signup
     * Get Or Create User
     * @param loginFinishedIn 
     */
    public getOrCreateUserAuthenticationLoginFinishedPost(loginFinishedIn: LoginFinishedIn, options?: Configuration): Promise<LoginFinishedOut> {
        const result = this.api.getOrCreateUserAuthenticationLoginFinishedPost(loginFinishedIn, options);
        return result.toPromise();
    }

    /**
     * List all API tokens
     * List Api Tokens
     * @param iterator 
     * @param limit 
     */
    public listApiTokensAuthenticationApiTokenGet(iterator?: string, limit?: number, options?: Configuration): Promise<ListResponseAuthTokenCensoredOut> {
        const result = this.api.listApiTokensAuthenticationApiTokenGet(iterator, limit, options);
        return result.toPromise();
    }

    /**
     * Resend Verification Email
     */
    public resendVerificationEmailAuthenticationResendVerificationEmailPost(options?: Configuration): Promise<void> {
        const result = this.api.resendVerificationEmailAuthenticationResendVerificationEmailPost(options);
        return result.toPromise();
    }

    /**
     * Set Welcome Questionnaire
     * @param questionnaireIn 
     */
    public setWelcomeQuestionnaireAuthenticationWelcomeQuestionnairePost(questionnaireIn: QuestionnaireIn, options?: Configuration): Promise<void> {
        const result = this.api.setWelcomeQuestionnaireAuthenticationWelcomeQuestionnairePost(questionnaireIn, options);
        return result.toPromise();
    }

    /**
     * Update the name of the selected auth token
     * Update Api Token
     * @param keyId 
     * @param authTokenUpdateIn 
     */
    public updateApiTokenAuthenticationApiTokenKeyIdPut(keyId: string, authTokenUpdateIn: AuthTokenUpdateIn, options?: Configuration): Promise<void> {
        const result = this.api.updateApiTokenAuthenticationApiTokenKeyIdPut(keyId, authTokenUpdateIn, options);
        return result.toPromise();
    }

    /**
     * Update Metadata
     * @param userMetadata 
     */
    public updateMetadataAuthenticationMetadataPatch(userMetadata: UserMetadata, options?: Configuration): Promise<UserMetadata> {
        const result = this.api.updateMetadataAuthenticationMetadataPatch(userMetadata, options);
        return result.toPromise();
    }


}



import { ObservableBillingApi } from './ObservableAPI';

import { BillingApiRequestFactory, BillingApiResponseProcessor} from "../apis/BillingApi";
export class PromiseBillingApi {
    private api: ObservableBillingApi

    public constructor(
        configuration: Configuration,
        requestFactory?: BillingApiRequestFactory,
        responseProcessor?: BillingApiResponseProcessor
    ) {
        this.api = new ObservableBillingApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Change a subscription back to the Starter plan
     * Change Subscription
     * @param subscriptionPatchIn 
     * @param setupIntentId 
     */
    public changeSubscriptionBillingSubscriptionPatch(subscriptionPatchIn: SubscriptionPatchIn, setupIntentId?: string, options?: Configuration): Promise<SubscriptionOut> {
        const result = this.api.changeSubscriptionBillingSubscriptionPatch(subscriptionPatchIn, setupIntentId, options);
        return result.toPromise();
    }

    /**
     * Get the billing alerts for the current user's organization group
     * Get Billing Alerts
     */
    public getBillingAlertsBillingAlertsGet(options?: Configuration): Promise<BillingAlertsOut> {
        const result = this.api.getBillingAlertsBillingAlertsGet(options);
        return result.toPromise();
    }

    /**
     * Get the subscription details for the current user
     * Get Payment Methods
     */
    public getPaymentMethodsBillingPaymentMethodsGet(options?: Configuration): Promise<ListResponsePaymentMethodOut> {
        const result = this.api.getPaymentMethodsBillingPaymentMethodsGet(options);
        return result.toPromise();
    }

    /**
     * Get the stripe billing dashboard for the user
     * Get Stripe Dashboard Link
     */
    public getStripeDashboardLinkBillingGet(options?: Configuration): Promise<BillingOut> {
        const result = this.api.getStripeDashboardLinkBillingGet(options);
        return result.toPromise();
    }

    /**
     * Get the subscription details for the current user
     * Get Subscription Details
     */
    public getSubscriptionDetailsBillingSubscriptionGet(options?: Configuration): Promise<SubscriptionOut> {
        const result = this.api.getSubscriptionDetailsBillingSubscriptionGet(options);
        return result.toPromise();
    }

    /**
     * Create a SetupIntent
     * Start Billing Flow
     */
    public startBillingFlowBillingIntentPost(options?: Configuration): Promise<StripeSetupIntentOut> {
        const result = this.api.startBillingFlowBillingIntentPost(options);
        return result.toPromise();
    }

    /**
     * Update the billing alerts for the current user's organization group
     * Update Billing Alerts
     * @param billingAlertsIn 
     */
    public updateBillingAlertsBillingAlertsPatch(billingAlertsIn: BillingAlertsIn, options?: Configuration): Promise<BillingAlertsOut> {
        const result = this.api.updateBillingAlertsBillingAlertsPatch(billingAlertsIn, options);
        return result.toPromise();
    }


}



import { ObservableEventCatalogApi } from './ObservableAPI';

import { EventCatalogApiRequestFactory, EventCatalogApiResponseProcessor} from "../apis/EventCatalogApi";
export class PromiseEventCatalogApi {
    private api: ObservableEventCatalogApi

    public constructor(
        configuration: Configuration,
        requestFactory?: EventCatalogApiRequestFactory,
        responseProcessor?: EventCatalogApiResponseProcessor
    ) {
        this.api = new ObservableEventCatalogApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Return the list of event types.
     * List Event Types
     * @param orgId 
     * @param iterator 
     * @param limit 
     */
    public listEventTypesEventTypesOrgIdGet(orgId: string, iterator?: string, limit?: number, options?: Configuration): Promise<EventCatalogOut> {
        const result = this.api.listEventTypesEventTypesOrgIdGet(orgId, iterator, limit, options);
        return result.toPromise();
    }


}



import { ObservableIntegrationApi } from './ObservableAPI';

import { IntegrationApiRequestFactory, IntegrationApiResponseProcessor} from "../apis/IntegrationApi";
export class PromiseIntegrationApi {
    private api: ObservableIntegrationApi

    public constructor(
        configuration: Configuration,
        requestFactory?: IntegrationApiRequestFactory,
        responseProcessor?: IntegrationApiResponseProcessor
    ) {
        this.api = new ObservableIntegrationApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Build Zapier Integration Package
     */
    public buildZapierIntegrationPackageIntegrationsClientZapierPost(options?: Configuration): Promise<ZapierIntegrationPackageOut> {
        const result = this.api.buildZapierIntegrationPackageIntegrationsClientZapierPost(options);
        return result.toPromise();
    }

    /**
     * Check if the Vercel integration is enabled for the authenticated account
     * Check Vercel Integration
     */
    public checkVercelIntegrationIntegrationsVercelGet(options?: Configuration): Promise<VercelEnabledOut> {
        const result = this.api.checkVercelIntegrationIntegrationsVercelGet(options);
        return result.toPromise();
    }

    /**
     * Get a list of Vercel projects connected to the authenticated account
     * Get Vercel Projects
     * @param limit 
     * @param fromStamp 
     */
    public getVercelProjectsIntegrationsVercelProjectGet(limit?: number, fromStamp?: string, options?: Configuration): Promise<ListResponseVercelProjectOut> {
        const result = this.api.getVercelProjectsIntegrationsVercelProjectGet(limit, fromStamp, options);
        return result.toPromise();
    }

    /**
     * Map a Vercel Project to a Svix Environment
     * Map Vercel Project To Svix Environment
     * @param projectId 
     * @param orgId 
     */
    public mapVercelProjectToSvixEnvironmentIntegrationsVercelProjectProjectIdMapToOrgIdPut(projectId: string, orgId: string, options?: Configuration): Promise<void> {
        const result = this.api.mapVercelProjectToSvixEnvironmentIntegrationsVercelProjectProjectIdMapToOrgIdPut(projectId, orgId, options);
        return result.toPromise();
    }

    /**
     * Persist the Vercel access token to the authenticated account
     * Set Vercel Token
     * @param vercelEnvVarsIn 
     */
    public setVercelTokenIntegrationsVercelPost(vercelEnvVarsIn: VercelEnvVarsIn, options?: Configuration): Promise<void> {
        const result = this.api.setVercelTokenIntegrationsVercelPost(vercelEnvVarsIn, options);
        return result.toPromise();
    }


}



import { ObservableInvitationApi } from './ObservableAPI';

import { InvitationApiRequestFactory, InvitationApiResponseProcessor} from "../apis/InvitationApi";
export class PromiseInvitationApi {
    private api: ObservableInvitationApi

    public constructor(
        configuration: Configuration,
        requestFactory?: InvitationApiRequestFactory,
        responseProcessor?: InvitationApiResponseProcessor
    ) {
        this.api = new ObservableInvitationApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Invite a user (email address) to an organization
     * Create Invite
     * @param inviteIn 
     */
    public createInviteInvitePost(inviteIn: InviteIn, options?: Configuration): Promise<InviteOut> {
        const result = this.api.createInviteInvitePost(inviteIn, options);
        return result.toPromise();
    }

    /**
     * Delete an invite
     * Delete Invite
     * @param orgGroupInviteId 
     */
    public deleteInviteInviteOrgGroupInviteIdDelete(orgGroupInviteId: string, options?: Configuration): Promise<void> {
        const result = this.api.deleteInviteInviteOrgGroupInviteIdDelete(orgGroupInviteId, options);
        return result.toPromise();
    }

    /**
     * Find Invite By Token
     * @param findInviteByTokenIn 
     */
    public findInviteByTokenInviteFindPost(findInviteByTokenIn: FindInviteByTokenIn, options?: Configuration): Promise<InviteOut> {
        const result = this.api.findInviteByTokenInviteFindPost(findInviteByTokenIn, options);
        return result.toPromise();
    }

    /**
     * Get the invite items of the organization group the user is authenticated for (The users that are invited to the current organization group)
     * List Org Group Invites
     * @param iterator 
     * @param limit 
     * @param inviteStatus 
     */
    public listOrgGroupInvitesInviteOrgGroupGet(iterator?: string, limit?: number, inviteStatus?: OrganizationInviteStatus, options?: Configuration): Promise<ListResponseInviteOut> {
        const result = this.api.listOrgGroupInvitesInviteOrgGroupGet(iterator, limit, inviteStatus, options);
        return result.toPromise();
    }

    /**
     * Respond to an invite
     * Respond To Invite
     * @param orgGroupInviteId 
     * @param inviteResponseIn 
     */
    public respondToInviteInviteRespondOrgGroupInviteIdPost(orgGroupInviteId: string, inviteResponseIn: InviteResponseIn, options?: Configuration): Promise<InviteOut> {
        const result = this.api.respondToInviteInviteRespondOrgGroupInviteIdPost(orgGroupInviteId, inviteResponseIn, options);
        return result.toPromise();
    }


}



import { ObservableMultifactorAuthenticationApi } from './ObservableAPI';

import { MultifactorAuthenticationApiRequestFactory, MultifactorAuthenticationApiResponseProcessor} from "../apis/MultifactorAuthenticationApi";
export class PromiseMultifactorAuthenticationApi {
    private api: ObservableMultifactorAuthenticationApi

    public constructor(
        configuration: Configuration,
        requestFactory?: MultifactorAuthenticationApiRequestFactory,
        responseProcessor?: MultifactorAuthenticationApiResponseProcessor
    ) {
        this.api = new ObservableMultifactorAuthenticationApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Confirm Enroll Mfa
     * @param verificationCodeIn 
     */
    public confirmEnrollMfaAuthenticationMfaVerifyPost(verificationCodeIn: VerificationCodeIn, options?: Configuration): Promise<MfaValidation> {
        const result = this.api.confirmEnrollMfaAuthenticationMfaVerifyPost(verificationCodeIn, options);
        return result.toPromise();
    }

    /**
     * Enroll Mfa
     */
    public enrollMfaAuthenticationMfaAddPost(options?: Configuration): Promise<MfaEnrollment> {
        const result = this.api.enrollMfaAuthenticationMfaAddPost(options);
        return result.toPromise();
    }

    /**
     * Get Authenticators
     */
    public getAuthenticatorsAuthenticationMfaGet(options?: Configuration): Promise<Array<Authenticator>> {
        const result = this.api.getAuthenticatorsAuthenticationMfaGet(options);
        return result.toPromise();
    }

    /**
     * Opt Out Mfa
     * @param verificationCodeIn 
     */
    public optOutMfaAuthenticationMfaRemovePost(verificationCodeIn: VerificationCodeIn, options?: Configuration): Promise<void> {
        const result = this.api.optOutMfaAuthenticationMfaRemovePost(verificationCodeIn, options);
        return result.toPromise();
    }

    /**
     * Verify the recovery code provided and generate a new recovery code to be returned to the user. Referred to as \"recovery code challenge\" based on auth0 documentation: https://auth0.com/docs/mfa/authenticate-with-ropg-and-mfa/manage-authenticator-factors-mfa-api/challenge-with-recovery-codes
     * Recovery Code Challenge
     * @param recoveryCodeIn 
     */
    public recoveryCodeChallengeAuthenticationMfaRecoveryCodePost(recoveryCodeIn: RecoveryCodeIn, options?: Configuration): Promise<RecoveryCode> {
        const result = this.api.recoveryCodeChallengeAuthenticationMfaRecoveryCodePost(recoveryCodeIn, options);
        return result.toPromise();
    }


}



import { ObservableOrganizationApi } from './ObservableAPI';

import { OrganizationApiRequestFactory, OrganizationApiResponseProcessor} from "../apis/OrganizationApi";
export class PromiseOrganizationApi {
    private api: ObservableOrganizationApi

    public constructor(
        configuration: Configuration,
        requestFactory?: OrganizationApiRequestFactory,
        responseProcessor?: OrganizationApiResponseProcessor
    ) {
        this.api = new ObservableOrganizationApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Update the user's org group role
     * Change Role
     * @param userId 
     * @param organizationRoleUpdate 
     */
    public changeRoleOrganizationRoleUserIdPost(userId: string, organizationRoleUpdate: OrganizationRoleUpdate, options?: Configuration): Promise<void> {
        const result = this.api.changeRoleOrganizationRoleUserIdPost(userId, organizationRoleUpdate, options);
        return result.toPromise();
    }

    /**
     * Create Org
     * @param organizationIn 
     */
    public createOrgOrganizationPost(organizationIn: OrganizationIn, options?: Configuration): Promise<OrganizationOut> {
        const result = this.api.createOrgOrganizationPost(organizationIn, options);
        return result.toPromise();
    }

    /**
     * Delete Org
     * @param orgId 
     */
    public deleteOrgOrganizationOrgIdDelete(orgId: string, options?: Configuration): Promise<void> {
        const result = this.api.deleteOrgOrganizationOrgIdDelete(orgId, options);
        return result.toPromise();
    }

    /**
     * Update the user's org group role
     * Delete Override
     * @param envId 
     * @param userId 
     */
    public deleteOverrideOrganizationRoleUserIdOverridesEnvIdDelete(envId: string, userId: string, options?: Configuration): Promise<void> {
        const result = this.api.deleteOverrideOrganizationRoleUserIdOverridesEnvIdDelete(envId, userId, options);
        return result.toPromise();
    }

    /**
     * Get User
     * @param userId 
     */
    public getUserOrganizationUsersUserIdGet(userId: string, options?: Configuration): Promise<OrganizationUser> {
        const result = this.api.getUserOrganizationUsersUserIdGet(userId, options);
        return result.toPromise();
    }

    /**
     * List Orgs
     */
    public listOrgsOrganizationGet(options?: Configuration): Promise<ListResponseOrganizationOut> {
        const result = this.api.listOrgsOrganizationGet(options);
        return result.toPromise();
    }

    /**
     * Get the user's org role overrides
     * List Overrides
     * @param userId 
     */
    public listOverridesOrganizationRoleUserIdOverridesGet(userId: string, options?: Configuration): Promise<ListResponseOrganizationRoleOverride> {
        const result = this.api.listOverridesOrganizationRoleUserIdOverridesGet(userId, options);
        return result.toPromise();
    }

    /**
     * List Users
     */
    public listUsersOrganizationUsersGet(options?: Configuration): Promise<ListResponseOrganizationUserOut> {
        const result = this.api.listUsersOrganizationUsersGet(options);
        return result.toPromise();
    }

    /**
     * Remove user from the current org group
     * Revoke Access
     * @param userId 
     */
    public revokeAccessOrganizationUsersRevokeAccessUserIdPost(userId: string, options?: Configuration): Promise<void> {
        const result = this.api.revokeAccessOrganizationUsersRevokeAccessUserIdPost(userId, options);
        return result.toPromise();
    }

    /**
     * Update Org
     * @param orgId 
     * @param organizationUpdate 
     */
    public updateOrgOrganizationOrgIdPut(orgId: string, organizationUpdate: OrganizationUpdate, options?: Configuration): Promise<OrganizationOut> {
        const result = this.api.updateOrgOrganizationOrgIdPut(orgId, organizationUpdate, options);
        return result.toPromise();
    }

    /**
     * Update the user's org group role
     * Update Override
     * @param envId 
     * @param userId 
     * @param organizationRoleUpdate 
     */
    public updateOverrideOrganizationRoleUserIdOverridesEnvIdPost(envId: string, userId: string, organizationRoleUpdate: OrganizationRoleUpdate, options?: Configuration): Promise<void> {
        const result = this.api.updateOverrideOrganizationRoleUserIdOverridesEnvIdPost(envId, userId, organizationRoleUpdate, options);
        return result.toPromise();
    }


}



import { ObservableOrganizationEEApi } from './ObservableAPI';

import { OrganizationEEApiRequestFactory, OrganizationEEApiResponseProcessor} from "../apis/OrganizationEEApi";
export class PromiseOrganizationEEApi {
    private api: ObservableOrganizationEEApi

    public constructor(
        configuration: Configuration,
        requestFactory?: OrganizationEEApiRequestFactory,
        responseProcessor?: OrganizationEEApiResponseProcessor
    ) {
        this.api = new ObservableOrganizationEEApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Create Org
     * @param organizationIn 
     */
    public createOrgEeOrganizationPost(organizationIn: OrganizationIn, options?: Configuration): Promise<OrganizationOutEE> {
        const result = this.api.createOrgEeOrganizationPost(organizationIn, options);
        return result.toPromise();
    }

    /**
     * Delete Org
     * @param orgId 
     */
    public deleteOrgEeOrganizationOrgIdDelete(orgId: string, options?: Configuration): Promise<void> {
        const result = this.api.deleteOrgEeOrganizationOrgIdDelete(orgId, options);
        return result.toPromise();
    }

    /**
     * List Orgs
     */
    public listOrgsEeOrganizationGet(options?: Configuration): Promise<ListResponseOrganizationOutEE> {
        const result = this.api.listOrgsEeOrganizationGet(options);
        return result.toPromise();
    }

    /**
     * Update Org
     * @param orgId 
     * @param organizationUpdate 
     */
    public updateOrgEeOrganizationOrgIdPut(orgId: string, organizationUpdate: OrganizationUpdate, options?: Configuration): Promise<OrganizationOutEE> {
        const result = this.api.updateOrgEeOrganizationOrgIdPut(orgId, organizationUpdate, options);
        return result.toPromise();
    }


}



import { ObservableOrganizationGroupApi } from './ObservableAPI';

import { OrganizationGroupApiRequestFactory, OrganizationGroupApiResponseProcessor} from "../apis/OrganizationGroupApi";
export class PromiseOrganizationGroupApi {
    private api: ObservableOrganizationGroupApi

    public constructor(
        configuration: Configuration,
        requestFactory?: OrganizationGroupApiRequestFactory,
        responseProcessor?: OrganizationGroupApiResponseProcessor
    ) {
        this.api = new ObservableOrganizationGroupApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Get Org Group Settings
     */
    public getOrgGroupSettingsOrganizationGroupGet(options?: Configuration): Promise<OrganizationGroupSettingsOut> {
        const result = this.api.getOrgGroupSettingsOrganizationGroupGet(options);
        return result.toPromise();
    }

    /**
     * Update Org Group Settings
     * @param organizationGroupSettingUpdate 
     */
    public updateOrgGroupSettingsOrganizationGroupPut(organizationGroupSettingUpdate: OrganizationGroupSettingUpdate, options?: Configuration): Promise<OrganizationGroupSettingsOut> {
        const result = this.api.updateOrgGroupSettingsOrganizationGroupPut(organizationGroupSettingUpdate, options);
        return result.toPromise();
    }


}



import { ObservableOrganizationSettingsApi } from './ObservableAPI';

import { OrganizationSettingsApiRequestFactory, OrganizationSettingsApiResponseProcessor} from "../apis/OrganizationSettingsApi";
export class PromiseOrganizationSettingsApi {
    private api: ObservableOrganizationSettingsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: OrganizationSettingsApiRequestFactory,
        responseProcessor?: OrganizationSettingsApiResponseProcessor
    ) {
        this.api = new ObservableOrganizationSettingsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Delete the organization's customer otel config
     * Otel Settings Delete
     */
    public otelSettingsDeleteOrganizationSettingsCustomerOtelDelete(options?: Configuration): Promise<void> {
        const result = this.api.otelSettingsDeleteOrganizationSettingsCustomerOtelDelete(options);
        return result.toPromise();
    }

    /**
     * Update the organization's customer otel config
     * Otel Settings Update
     * @param otelConfig 
     */
    public otelSettingsUpdateOrganizationSettingsCustomerOtelPut(otelConfig: OtelConfig, options?: Configuration): Promise<void> {
        const result = this.api.otelSettingsUpdateOrganizationSettingsCustomerOtelPut(otelConfig, options);
        return result.toPromise();
    }

    /**
     * Get the organization's settings
     * Settings Get
     */
    public settingsGetOrganizationSettingsGet(options?: Configuration): Promise<SettingsInternalOut> {
        const result = this.api.settingsGetOrganizationSettingsGet(options);
        return result.toPromise();
    }

    /**
     * Update the organization's settings
     * Settings Update
     * @param settingsInternalIn 
     */
    public settingsUpdateOrganizationSettingsPut(settingsInternalIn: SettingsInternalIn, options?: Configuration): Promise<SettingsInternalOut> {
        const result = this.api.settingsUpdateOrganizationSettingsPut(settingsInternalIn, options);
        return result.toPromise();
    }


}



import { ObservableSearchApi } from './ObservableAPI';

import { SearchApiRequestFactory, SearchApiResponseProcessor} from "../apis/SearchApi";
export class PromiseSearchApi {
    private api: ObservableSearchApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SearchApiRequestFactory,
        responseProcessor?: SearchApiResponseProcessor
    ) {
        this.api = new ObservableSearchApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * A generic search endpoint that returns results from all the db (usually used for searching IDs)
     * Search Db
     * @param searchQueryIn 
     * @param limit 
     */
    public searchDbSearchPost(searchQueryIn: SearchQueryIn, limit?: number, options?: Configuration): Promise<ListResponseSearchResultOut> {
        const result = this.api.searchDbSearchPost(searchQueryIn, limit, options);
        return result.toPromise();
    }


}



import { ObservableSlackApi } from './ObservableAPI';

import { SlackApiRequestFactory, SlackApiResponseProcessor} from "../apis/SlackApi";
export class PromiseSlackApi {
    private api: ObservableSlackApi

    public constructor(
        configuration: Configuration,
        requestFactory?: SlackApiRequestFactory,
        responseProcessor?: SlackApiResponseProcessor
    ) {
        this.api = new ObservableSlackApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Get Slack App
     */
    public getSlackAppSlackAppGet(options?: Configuration): Promise<SlackAppOut> {
        const result = this.api.getSlackAppSlackAppGet(options);
        return result.toPromise();
    }

    /**
     * Register Slack App
     * @param slackAppRegisterIn 
     */
    public registerSlackAppSlackAppPost(slackAppRegisterIn: SlackAppRegisterIn, options?: Configuration): Promise<SlackAppOut> {
        const result = this.api.registerSlackAppSlackAppPost(slackAppRegisterIn, options);
        return result.toPromise();
    }


}



import { ObservableStatisticsApi } from './ObservableAPI';

import { StatisticsApiRequestFactory, StatisticsApiResponseProcessor} from "../apis/StatisticsApi";
export class PromiseStatisticsApi {
    private api: ObservableStatisticsApi

    public constructor(
        configuration: Configuration,
        requestFactory?: StatisticsApiRequestFactory,
        responseProcessor?: StatisticsApiResponseProcessor
    ) {
        this.api = new ObservableStatisticsApi(configuration, requestFactory, responseProcessor);
    }

    /**
     * Returns the total number of applications
     * Get App Count
     */
    public getAppCountStatsAppCountGet(options?: Configuration): Promise<ApplicationCountResponse> {
        const result = this.api.getAppCountStatsAppCountGet(options);
        return result.toPromise();
    }

    /**
     * Returns the event types that this application is subscribed to, as well as a flag to indicate if any endpoints are subscribed to all event types.
     * Get App Event Types
     * @param appId 
     */
    public getAppEventTypesStatsAppAppIdEventTypesGet(appId: string, options?: Configuration): Promise<ApplicationEventTypesResponse> {
        const result = this.api.getAppEventTypesStatsAppAppIdEventTypesGet(appId, options);
        return result.toPromise();
    }

    /**
     * Returns the total number of endpoints tied to the current org
     * Get Endpoint Count
     */
    public getEndpointCountStatsEndpointCountGet(options?: Configuration): Promise<EndpointCountResponse> {
        const result = this.api.getEndpointCountStatsEndpointCountGet(options);
        return result.toPromise();
    }

    /**
     * Get Org Group Messages Stats
     * @param startDate 
     * @param endDate 
     */
    public getOrgGroupMessagesStatsStatsOrgGroupMessagesGet(startDate?: Date, endDate?: Date, options?: Configuration): Promise<OrganizationMessageStatisticsResponse> {
        const result = this.api.getOrgGroupMessagesStatsStatsOrgGroupMessagesGet(startDate, endDate, options);
        return result.toPromise();
    }

    /**
     * Get Org Messages Stats
     * @param startDate 
     * @param endDate 
     */
    public getOrgMessagesStatsStatsOrgMessagesGet(startDate?: Date, endDate?: Date, options?: Configuration): Promise<OrganizationMessageStatisticsResponse> {
        const result = this.api.getOrgMessagesStatsStatsOrgMessagesGet(startDate, endDate, options);
        return result.toPromise();
    }


}




import "svix-react/style.css";
import { Heading, Text } from "@chakra-ui/react";
import { useQuery } from "react-query";
import { AppPortal } from "svix-react";

import * as C from "@svix/common/constants";
import Link from "@svix/common/widgets/Link";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import ResourceError from "@svix/common/widgets/ResourceError";

import { getApiConfiguration } from "src/api";
import { AuthenticationApi, DashboardAccessOut } from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";

export default function Webhooks() {
  const darkMode = useAppSelector((state) => state.settings.darkMode);
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId);

  const {
    data: portal,
    error,
    refetch,
  } = useQuery(["environments", activeEnvId, "operationalWebhooks"], async () => {
    const config = await getApiConfiguration();
    const authApi = new AuthenticationApi(config);
    return authApi.getDashboardAuthenticationGetDashboardGet();
  });

  if (error) {
    return <ResourceError resourceName="operational webhooks" onClick={refetch} />;
  }

  return (
    <>
      <MetaTitle path={["Operational Webhooks"]} />
      <Heading as="h1" mt={2} size="lg" mb={6}>
        Operational Webhooks
      </Heading>
      <Text variant="caption">
        Manage what webhooks you receive from Svix about your service and where you
        receive them.
      </Text>
      <Text variant="caption" mt={2} mb={8}>
        Additional information can be found in the{" "}
        <Link isExternal href={C.docs.operationalWebhooks}>
          operational webhooks
        </Link>{" "}
        section of the docs.
      </Text>
      <AppPortal
        url={portal?.url && getAppPortalUrl(portal)}
        fullSize
        darkMode={darkMode}
      />
    </>
  );
}

const getAppPortalUrl = (portal: DashboardAccessOut) => {
  const url = new URL(portal.url);
  url.searchParams.set("noGutters", "true");
  return url.toString();
};

import * as React from "react";
import { Flex, useColorMode } from "@chakra-ui/react";

import LoadingIndicator from "@svix/common/widgets/LoadingIndicator";

import { useAppSelector } from "src/hooks/store";
import AppBar from "./AppBar";
import Drawer, { drawerWidth } from "./Drawer";
import GlobalErrors from "../GlobalErrors";

interface IAuthedChromeProps {
  children: React.ReactNode;
}

export default function AuthedChrome({ children }: IAuthedChromeProps) {
  const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState(false);
  const { colorMode } = useColorMode();
  const { isLoggedIn } = useAppSelector((state) => state.auth);

  return (
    <>
      <AppBar
        mobileDrawerOpen={mobileDrawerOpen}
        setMobileDrawerOpen={setMobileDrawerOpen}
      />
      <Drawer
        mobileDrawerOpen={mobileDrawerOpen}
        setMobileDrawerOpen={setMobileDrawerOpen}
      />
      <Flex
        as="main"
        flexDir="column"
        flexGrow={1}
        bg="background.primary"
        color="text.primary"
        className={`theme-${colorMode}`}
        ml={[0, 0, drawerWidth]}
        p={8}
      >
        {isLoggedIn ? (
          children
        ) : (
          <LoadingIndicator style={{ display: "block", margin: "40px auto" }} />
        )}
        <GlobalErrors />
      </Flex>
    </>
  );
}

import { useState } from "react";
import {
  Heading,
  Box,
  SkeletonText,
  Th,
  Tbody,
  Thead,
  Tr,
  HStack,
} from "@chakra-ui/react";

import usePagination from "@svix/common/hooks/pagination";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { getApiConfiguration } from "src/api";
import EnvMenu from "src/components/EnvMenu";
import { OrganizationApi } from "src/generated/dashboard-openapi";
import { OrganizationUserOut } from "src/generated/dashboard-openapi/models/OrganizationUserOut";
import { useAppSelector } from "src/hooks/store";
import MemberRow from "./MemberRow";

export default function MembersTable() {
  const [members, membersCtx] = usePagination("members", async () => {
    const config = await getApiConfiguration();
    const orgApi = new OrganizationApi(config);
    return orgApi.listUsersOrganizationUsersGet();
  });
  const envs = useAppSelector((state) => state.auth.environments);
  const [envFilter, setEnvFilter] = useState<string>("all");
  const selectedEnv = envs.find((env) => env.orgId === envFilter);

  return (
    <Box mt={2}>
      <Heading as="h2" size="sm" mb={4}>
        <HStack justifyContent="space-between">
          <p>Members</p>
          <EnvMenu
            showAll
            selectedEnvId={envFilter}
            setSelectedEnvId={(envId) => setEnvFilter(envId)}
          />
        </HStack>
      </Heading>
      <Table response={members} requestElems={membersCtx} data-cy="members-table">
        <Thead>
          <Tr>
            <Th>Email</Th>
            <Th>{selectedEnv ? "Role" : "Roles"}</Th>
            <Th></Th>
          </Tr>
        </Thead>
        <Tbody>
          {members?.data
            .filter(memberEnvFilter(selectedEnv?.orgId))
            .map((member: OrganizationUserOut) => (
              <MemberRow
                key={member.userId}
                member={member}
                filterByOrgId={selectedEnv?.orgId}
              />
            ))}
          {membersCtx.isLoading && (
            <Tr>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
}

const memberEnvFilter = (orgId?: string) => {
  if (!orgId) {
    return () => true;
  }

  return (member: OrganizationUserOut) => {
    const override = member.roleOverrides.find((o) => o.org === orgId);
    if (override) {
      return override.role !== "NoAccess";
    } else {
      return member.orgGroupRole !== "NoAccess";
    }
  };
};

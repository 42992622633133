import * as React from "react";
import {
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useBoolean,
  useColorModeValue,
} from "@chakra-ui/react";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useWatch } from "react-hook-form";

import useKeyboardShortcut from "@svix/common/hooks/keyboardShortcut";
import { setSearch } from "@svix/common/hooks/search";
import Button from "@svix/common/widgets/Button";
import DateFilter, {
  CUSTOM_DATE_FILTER,
  EARLIER_TODAY_FILTER,
  LAST_MONTH_FILTER,
  LAST_WEEK_FILTER,
  NOW_FILTER,
  EARLIEST_DATE_FILTER,
  YESTERDAY_FILTER,
} from "@svix/common/widgets/DateFilter/DateFilter";

const beforeDateQuickChoices = [
  NOW_FILTER,
  EARLIER_TODAY_FILTER,
  YESTERDAY_FILTER,
  LAST_WEEK_FILTER,
  LAST_MONTH_FILTER,
  CUSTOM_DATE_FILTER,
];

const afterDateQuickChoices = [
  EARLIEST_DATE_FILTER,
  LAST_MONTH_FILTER,
  LAST_WEEK_FILTER,
  YESTERDAY_FILTER,
  EARLIER_TODAY_FILTER,
  CUSTOM_DATE_FILTER,
];

interface IFilterMenuProps {
  control: any;
  filterCount: number;
}

export default function FilterMenu(props: IFilterMenuProps) {
  const { control, filterCount } = props;
  const [showFilterMenu, setShowFilterMenu] = useBoolean();
  const filterInputRef = React.useRef<HTMLInputElement | null>(null);

  const badgeColor = useColorModeValue("gray.200", "gray.600");
  const toolbarColor = useColorModeValue("white", "gray.800");

  useKeyboardShortcut("t", null, setShowFilterMenu.on);

  // Save filters to query params
  const afterFilter = useWatch({ control: control, name: "after" });
  const beforeFilter = useWatch({ control: control, name: "before" });

  React.useEffect(() => {
    setSearch({
      before: beforeFilter ? beforeFilter.value : undefined,
      after: afterFilter ? afterFilter.value : undefined,
    });
  }, [beforeFilter, afterFilter]);

  return (
    <Popover
      initialFocusRef={filterInputRef}
      isOpen={showFilterMenu}
      onClose={setShowFilterMenu.off}
    >
      <PopoverTrigger>
        <Button
          onClick={setShowFilterMenu.toggle}
          bgColor={toolbarColor}
          colorScheme="gray"
          variant="outline"
          size="sm"
          leftIcon={<FilterListIcon style={{ fontSize: 16 }} />}
        >
          Filters
          {filterCount > 0 && (
            <Flex
              alignItems="center"
              justifyContent="center"
              ml={2}
              w={5}
              h={5}
              fontSize="sm"
              bgColor={badgeColor}
              borderRadius="full"
            >
              {filterCount}
            </Flex>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent mx={2} w="sm">
        <PopoverArrow />
        <PopoverBody p={3}>
          <Tabs isFitted colorScheme="brand">
            <TabList>
              <Tab fontWeight="500" fontSize="xs" px={0}>
                After Date
              </Tab>
              <Tab fontWeight="500" fontSize="xs" px={0}>
                Before Date
              </Tab>
            </TabList>

            <TabPanels>
              <TabPanel p={0} mt={1}>
                <DateFilter
                  control={control}
                  name="after"
                  quickChoices={afterDateQuickChoices}
                />
              </TabPanel>
              <TabPanel p={0} mt={1}>
                <DateFilter
                  control={control}
                  name="before"
                  quickChoices={beforeDateQuickChoices}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}

import { HStack, Box, Tooltip } from "@chakra-ui/react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";

interface IIconTooltipProps {
  children: React.ReactNode;
  tooltip: string;
  color?: string;
  icon: typeof ErrorOutlineIcon;
}

export default function IconTooltip(props: IIconTooltipProps) {
  const { tooltip, color = "text.muted", icon: TooltipIcon, children } = props;
  return (
    <HStack alignItems="center" spacing={1}>
      <Box>{children}</Box>
      <Tooltip label={tooltip} placement="right">
        <Box color={color}>
          <TooltipIcon
            style={{
              height: "1.4rem",
              width: "1rem",
            }}
          />
        </Box>
      </Tooltip>
    </HStack>
  );
}

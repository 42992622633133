import { Text } from "@chakra-ui/react";
import MenuBook from "@material-ui/icons/MenuBook";

import * as C from "@svix/common/constants";
import Link from "@svix/common/widgets/Link";

import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { setDocumentationTaskDone } from "src/store/settings";
import GettingStartedCard from "../GettingStartedCard";

export default function DocumentationCard() {
  const dispatch = useAppDispatch();
  const { documentationTaskDone } = useAppSelector((store) => store.settings);

  return (
    <GettingStartedCard
      done={documentationTaskDone}
      icon={<MenuBook style={{ fontSize: 24 }} />}
      title="Write your webhooks documentation"
      cta={
        <Link
          href={C.docs.quickstart.documentingWebhooks}
          isExternal
          target="_blank"
          onClick={() => dispatch(setDocumentationTaskDone())}
        >
          Go to documentation guide
        </Link>
      }
    >
      <Text variant="caption">
        We have a guide to help you write great webhook documentation for your users.
      </Text>
    </GettingStartedCard>
  );
}

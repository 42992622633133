import { IconButton, Icon } from "@chakra-ui/react";
import { Brightness7, Brightness3 } from "@material-ui/icons";

import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { setSettings } from "src/store/settings";

export default function ColorModeToggle() {
  const dispatch = useAppDispatch();

  const darkMode = useAppSelector((state) => state.settings.darkMode);

  return (
    <IconButton
      variant="appbar"
      aria-label="toggle dark mode"
      onClick={() => {
        dispatch(setSettings({ darkMode: !darkMode }));
      }}
    >
      {darkMode ? (
        <Icon as={Brightness7} />
      ) : (
        <Icon transform="auto" rotate={9} as={Brightness3} />
      )}
    </IconButton>
  );
}

import { Fragment } from "react";
import {
  Heading,
  HStack,
  Box,
  SkeletonText,
  Stack,
  Thead,
  Text,
  Tr,
  Th,
  Tbody,
  Tooltip,
  Spacer,
  IconButton,
} from "@chakra-ui/react";
import { Replay, Code, Person } from "@material-ui/icons";
import { useWatch, useForm } from "react-hook-form";
import { useQuery } from "react-query";

import usePagination from "@svix/common/hooks/pagination";
import {
  useInitialDateFilter,
  NOW_FILTER,
  DateFilterChoice,
  EARLIEST_DATE_FILTER,
} from "@svix/common/widgets/DateFilter/DateFilter";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import ResourceError from "@svix/common/widgets/ResourceError";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { getApiConfiguration } from "src/api";
import {
  OrganizationApi,
  AuditLogApi,
  AuthenticationApi,
  AuthTokenCensoredOut,
  OrganizationUserOut,
  AuditLogRecord,
} from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import FilterMenu from "./FilterMenu";

export default function AuditLog() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;

  const { data: members, isLoading: membersIsLoading } = useQuery("members", async () => {
    const config = await getApiConfiguration();
    const orgApi = new OrganizationApi(config);
    return orgApi.listUsersOrganizationUsersGet();
  });

  const { data: authTokens, isLoading: authTokensIsLoading } = useQuery(
    ["environments", activeEnvId, "authTokens"],
    async () => {
      const config = await getApiConfiguration();
      const authApi = new AuthenticationApi(config);
      return authApi.listApiTokensAuthenticationApiTokenGet();
    }
  );

  return (
    <>
      <MetaTitle path={["Audit Log"]} />
      {members?.data && (
        <AuditLogsTable
          users={members.data}
          authTokens={authTokens?.data}
          isLoading={membersIsLoading || authTokensIsLoading}
        />
      )}
    </>
  );
}

function AuditLogsTable({
  users = [],
  authTokens = [],
  isLoading: isLoadingMetadata,
}: {
  users?: OrganizationUserOut[];
  authTokens?: AuthTokenCensoredOut[];
  isLoading: boolean;
}) {
  const { activeEnvId } = useAppSelector((store) => store.auth);

  const defaultValues = {
    before: useInitialDateFilter("before", NOW_FILTER),
    after: useInitialDateFilter("after", EARLIEST_DATE_FILTER),
  };

  const formCtx = useForm({ defaultValues });

  let filterCount = 0;

  const afterDateFilter: DateFilterChoice = useWatch({
    control: formCtx.control,
    name: "after",
    defaultValue: defaultValues.after,
  });
  if (afterDateFilter.value !== EARLIEST_DATE_FILTER.value) {
    filterCount++;
  }

  const beforeDateFilter: DateFilterChoice = useWatch({
    control: formCtx.control,
    name: "before",
    defaultValue: defaultValues.before,
  });
  if (beforeDateFilter.value !== NOW_FILTER.value) {
    filterCount++;
  }

  const [auditLogEvents, auditLogEventsCtx] = usePagination(
    [
      "environments",
      activeEnvId!,
      "auditLog",
      beforeDateFilter.value,
      afterDateFilter.value,
    ],
    async (iterator) => {
      const config = await getApiConfiguration();
      const auditLogApi = new AuditLogApi(config);
      const logs = await auditLogApi.getDashboardAuditLogAuditLogOrgIdGet(
        activeEnvId!,
        beforeDateFilter.getDate(),
        afterDateFilter.getDate(),
        iterator,
        20
      );

      return logs;
    }
  );

  if (auditLogEventsCtx.error) {
    return <ResourceError resourceName="audit log" onClick={auditLogEventsCtx.refetch} />;
  }

  const THead = (
    <Thead>
      <Tr>
        <Th>URI</Th>
        <Th>Method</Th>
        <Th>Status Code</Th>
        <Th>Actor</Th>
        {/* <Th>Auth Token</Th> */}
        <Th>Timestamp</Th>
      </Tr>
    </Thead>
  );

  const isLoading = auditLogEventsCtx.isLoading || isLoadingMetadata;

  return (
    <>
      <HStack pt={4} pb={4} spacing={4}>
        <Heading as="h1" size="lg">
          Audit Log
        </Heading>
        <Spacer />
        <FilterMenu control={formCtx.control} filterCount={filterCount} />
        <IconButton
          size="sm"
          backgroundColor="background.secondary"
          aria-label="Refresh"
          variant="outline"
          isLoading={auditLogEventsCtx.isFetching}
          onClick={auditLogEventsCtx.refetch}
        >
          <Replay style={{ fontSize: 16 }} />
        </IconButton>
      </HStack>

      {isLoading && (
        <Table
          data-cy="eventtypes-table"
          response={auditLogEvents}
          requestElems={auditLogEventsCtx}
        >
          {THead}
          <Tbody>
            <Tr>
              {[...Array(6)].map((_, idx) => (
                <TableCell align="left" key={idx}>
                  <SkeletonText noOfLines={1} />
                </TableCell>
              ))}
            </Tr>
          </Tbody>
        </Table>
      )}

      {!isLoading && auditLogEvents && auditLogEvents.data.length === 0 && (
        <Stack>
          <Text fontSize="lg" color="text.muted">
            No recent events. The audit log will record all the relevant activity in this
            environment.
          </Text>
        </Stack>
      )}

      {!isLoading && auditLogEvents && auditLogEvents.data.length > 0 && (
        <Table
          data-cy="eventtypes-table"
          variant="hover"
          response={auditLogEvents}
          requestElems={auditLogEventsCtx}
        >
          {THead}
          <Tbody>
            {auditLogEvents.data.map((al, idx) => {
              const user = users.find((u) => u.userId === al.userId);
              const token = authTokens.find((t) => t.id === al.authTokenId);
              const hasTooltip = user || token;
              return (
                <Fragment key={idx}>
                  <Tr
                    sx={{
                      "tbody &": {
                        _hover: {}, // Remove hover color
                      },
                    }}
                  >
                    <TableCell mono isTruncated maxW="48em">
                      {al.uri}
                    </TableCell>
                    <TableCell mono>{al.method}</TableCell>
                    <TableCell mono>{al.statusCode}</TableCell>
                    <TableCell isTruncated maxW="24em">
                      <HStack>
                        <ActorIcon al={al} />
                        <Tooltip label={user?.userId ?? token?.censoredToken}>
                          <Text
                            {...(hasTooltip && {
                              textDecoration: "underline",
                              textUnderlineOffset: "0.2em",
                              textDecorationStyle: "dotted",
                              textDecorationColor: "brand.500",
                            })}
                          >
                            {user?.email ?? al.userId}
                            {token?.name ?? al.authTokenId}
                          </Text>
                        </Tooltip>
                      </HStack>
                    </TableCell>
                    <TableCell>{al.timestamp.toLocaleString()}</TableCell>
                  </Tr>
                </Fragment>
              );
            })}
          </Tbody>
        </Table>
      )}
    </>
  );
}

function ActorIcon({ al }: { al: AuditLogRecord }) {
  const icon = al.userId ? (
    <Person style={{ fontSize: 14 }} />
  ) : (
    <Code style={{ fontSize: 14 }} />
  );
  return (
    <Tooltip label={al.userId ? "User" : "API token"}>
      <Box color="text.secondary" fontSize="sm">
        {icon}
      </Box>
    </Tooltip>
  );
}

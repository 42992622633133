import { Tag, Tr } from "@chakra-ui/react";
import { MessageOut } from "svix";

import TableCell from "@svix/common/widgets/TableCell";
import TimestampTableCell from "@svix/common/widgets/TimestampTableCell";

interface IMessageRowProps {
  message: MessageOut;
  enableChannels: boolean;
}

export default function MessageRow({ enableChannels, message }: IMessageRowProps) {
  return (
    <Tr>
      <TableCell mono>{message.eventType}</TableCell>
      <TableCell w="40%" maxW={0} isTruncated mono>
        {message.eventId ?? message.id}
      </TableCell>
      {enableChannels && (
        <TableCell w="20%" maxW={0} isTruncated mono>
          {message.channels &&
            message.channels.map((channel) => <Tag key={channel}>{channel}</Tag>)}
        </TableCell>
      )}
      <TimestampTableCell minW="13em" isNumeric ts={message.timestamp} />
    </Tr>
  );
}

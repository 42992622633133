import { useMemo, useState } from "react";
import { Box } from "@chakra-ui/react";
import { Step, Steps } from "chakra-ui-steps";
import { useHistory } from "react-router";

import { generateSvixPlayToken } from "@svix/common/play";

import { trackEvent } from "src/analytics";
import { getApiConfiguration } from "src/api";
import { AuthenticationApi } from "src/generated/dashboard-openapi";
import { useLoading } from "src/utils";
import ApplicationStep from "./steps/ApplicationStep";
import EndpointsStep from "./steps/EndpointsStep";
import IntroStep from "./steps/IntroStep";
import MessagesStep from "./steps/MessagesStep";

const ONBOARDING_STEPS = [IntroStep, ApplicationStep, MessagesStep, EndpointsStep];

export default function OnboardingTasksDialog() {
  const history = useHistory();
  const playToken = useMemo(generateSvixPlayToken, []);

  const [activeStepNum, setActiveStepNum] = useState(0);
  const [appUid, setAppUid] = useState("");

  function handleTeardown() {
    history.replace({ search: "" });
  }

  function handleClose() {
    trackEvent("Onboarding - Close", {
      step: activeStepNum,
    });
    handleTeardown();
  }

  // Fetch the org key to be displayed in the code snippets
  const [orgKey] = useLoading(async () => {
    const config = await getApiConfiguration();
    const authApi = new AuthenticationApi(config);
    const authToken = await authApi.createApiTokenAuthenticationApiTokenPost({
      name: "Onboarding wizard",
    });
    return authToken.token;
  }, []);

  const ActiveStep = ONBOARDING_STEPS[activeStepNum];
  const nextStep = () => {
    trackEvent("Onboarding - Next", {
      step: activeStepNum + 1,
    });
    setActiveStepNum(activeStepNum + 1);
  };

  return (
    <>
      <ActiveStep
        playToken={playToken}
        stepper={
          <Box p={4} pr={10}>
            <Steps orientation="horizontal" activeStep={activeStepNum - 1} size="sm">
              <Step label="Create an Application" />
              <Step label="Send a message" />
              <Step label="Done" />
            </Steps>
          </Box>
        }
        handleCancel={handleClose}
        handleComplete={handleTeardown}
        nextStep={nextStep}
        appUid={appUid}
        setAppUid={setAppUid}
        orgKey={orgKey}
      />
    </>
  );
}

import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react";
import { Input } from "@material-ui/icons";
import { Link } from "react-router-dom";

import { routeResolver } from "src/App";

export default function SourcesEmptyState() {
  return (
    <Stack
      width="100%"
      maxWidth={800}
      margin="0 auto"
      p={4}
      textAlign="center"
      alignItems="center"
      spacing={4}
    >
      <Box color="text.muted">
        <Input style={{ width: "48px", height: "48px" }} />
      </Box>
      <Heading as="h2" size="md" mt={8} mb={2}>
        No Sources
      </Heading>
      <Text variant="caption">To get started, create a source.</Text>
      <Button
        as={Link}
        variant="solid"
        colorScheme="brand"
        to={routeResolver.getRoute("in.sources.new")}
      >
        Create Source
      </Button>
    </Stack>
  );
}

import { createAuth0Client } from "@auth0/auth0-spa-js";
import { User } from "oidc-client-ts";
import { Svix, SvixOptions } from "svix";

import * as C from "@svix/common/constants";

import { OrganizationRegion } from "src/generated/dashboard-openapi/models/OrganizationRegion";
import { store } from "src/store";
import { IAuth } from "src/store/auth";
import { isEE } from "src/utils";
import {
  Configuration,
  createConfiguration,
  HttpBearerConfiguration,
  ServerConfiguration,
} from "../generated/dashboard-openapi";

export async function getAuth0Client(envId: string | null, isForMfa: boolean) {
  let mfaToken = null;
  if (isForMfa) {
    const mfaClient = await getMfaAuth0Client();
    mfaToken = await mfaClient.getTokenSilently();
  }

  const scope = `profile email ${
    // Pass in a dummy scope to auth0 to avoid it fetching the wrong token from localstorage
    envId ? `request_organization:${envId}` : "NO_ENVIRONMENT_SELECTED__BUST_CACHE"
  }${mfaToken ? ` mfa_jwt:${mfaToken}` : ``}`.trim();

  return await createAuth0Client({
    domain: C.envConfig.auth0.domain,
    clientId: C.envConfig.auth0.clientId,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: C.envConfig.auth0.audience,
      scope,
    },
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
    cacheLocation: "localstorage",
  });
}

export async function getMfaAuth0Client() {
  return await createAuth0Client({
    domain: C.envConfig.auth0.domain,
    clientId: C.envConfig.auth0.clientId,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: `https://${C.envConfig.auth0.mfa_domain}/mfa/`,
      scope: "enroll read:authenticators remove:authenticators",
    },
    cacheLocation: "localstorage",
  });
}

interface ApiConfigOptions {
  region?: OrganizationRegion;
  overrideEnvId?: string;
  noEnvSelected?: boolean;
  isForMfa?: boolean;
}

// FIXME: this has gotten very confusing and needs refactoring.
export async function getToken(opts?: ApiConfigOptions): Promise<string | null> {
  const { isForMfa = false, noEnvSelected = false, overrideEnvId } = { ...opts };

  if (import.meta.env.VITE_INJECTED_JWT) {
    return import.meta.env.VITE_INJECTED_JWT;
  }

  // Might be null during initial user creation, which is fine
  // If an explicit region is specified, don't use the active environment
  // in the token (it might be for a different region, and the backend shouldn't
  // need it for these kinds of API calls).
  let envId: string | null;
  if (opts?.region || noEnvSelected) {
    envId = null;
  } else {
    envId = overrideEnvId ?? store.getState().auth.activeEnvId;
  }

  let token: string;
  if (isEE) {
    if (C.isOIDCEE) {
      const oidcStorage = sessionStorage.getItem(
        `oidc.user:${C.envConfig.oidc?.authority}:${C.envConfig.oidc?.client_id}`
      )!;
      const user = User.fromStorageString(oidcStorage);
      token = user.id_token!; // '!' is safe because we requested the 'openid' scope
    } else {
      // Uses manually set JWT
      const envTokens = store.getState().auth.eeOrgTokens;
      token = envTokens[store.getState().auth.activeEnvId];
    }
  } else {
    const client = await getAuth0Client(envId, isForMfa);
    token = await client.getTokenSilently();
  }

  return envId ? `${token}${bearerOrgDelimiter}${envId}` : token;
}

export async function getApiConfiguration(
  opts?: ApiConfigOptions
): Promise<Configuration> {
  const region = opts?.region ?? getRegion();
  const baseUrl = C.envConfig.getServerUrl(region);
  const baseServer = new ServerConfiguration<any>(`${baseUrl}/dashboard`, {});

  const token = await getToken(opts);
  if (token === null) {
    return createConfiguration({
      baseServer,
    });
  } else {
    const bearerConfiguration: HttpBearerConfiguration = {
      tokenProvider: {
        getToken: () => token,
      },
    };

    return createConfiguration({
      baseServer,
      authMethods: {
        HTTPBearer: bearerConfiguration,
      },
    });
  }
}

interface EEApiConfigOptions {
  serverUrl?: string;
  token?: string;
}

export async function getEEAdminApiConfiguration(
  opts?: EEApiConfigOptions
): Promise<Configuration> {
  const region = getRegion();
  const baseUrl = opts?.serverUrl ?? C.envConfig.getServerUrl(region);
  const baseServer = new ServerConfiguration<any>(`${baseUrl}/dashboard`, {});

  let token: string | null;
  if (opts?.token) {
    token = opts.token;
  } else if (C.isOIDCEE) {
    token = await getToken();
  } else {
    // JWT token login
    token = store.getState().auth.eeAdminToken;
  }

  const bearerConfiguration: HttpBearerConfiguration = {
    tokenProvider: {
      getToken: () => token!,
    },
  };

  return createConfiguration({
    baseServer,
    authMethods: {
      HTTPBearer: bearerConfiguration,
    },
  });
}

export function getRegion(): OrganizationRegion {
  const auth = store.getState().auth as IAuth;
  const envId = auth.activeEnvId;
  if (!envId) {
    return "eu";
  }
  const activeEnv = auth.environments.find((env) => env.orgId === envId);
  const region = activeEnv?.region;
  if (!region) {
    throw new Error(
      `Failed to find region for environment ${envId} in state ${auth.environments}`
    );
  }
  return region;
}

const svixCache = new Map<string, Svix>();
const bearerOrgDelimiter = "|";

export async function getSvix(envId?: string) {
  const region = getRegion();
  const token = await getToken({ overrideEnvId: envId });

  if (!token) {
    throw new Error("Failed to retrieve valid token, user is not authenticated");
  }

  if (svixCache.has(token)) {
    return svixCache.get(token)!;
  }

  const options: SvixOptions = {
    serverUrl: C.envConfig.getServerUrl(region),
  };

  const ret = new Svix(token, options);
  svixCache.set(token, ret);
  return ret;
}

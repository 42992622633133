import { Analytics } from "@segment/analytics-next";

import * as C from "@svix/common/constants";
import { logError } from "@svix/common/logger";

declare global {
  interface Window {
    analytics: Analytics | undefined;
  }
}

export function trackEvent(event: string, properties?: object) {
  return analyticsWrapper(async () => {
    await window.analytics!.track(event, properties);
  });
}

export async function trackPageView() {
  return analyticsWrapper(async () => {
    await window.analytics!.page();
  });
}

export function reset() {
  return analyticsWrapper(() => {
    window.analytics!.reset();
  });
}

const analyticsWrapper = async (fn: (() => Promise<void>) | (() => void)) => {
  if (C.envConfig.name !== "prod") {
    return;
  }

  try {
    await fn();
  } catch (e) {
    logError(e);
  }
};

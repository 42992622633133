import {
  Box,
  Flex,
  Icon,
  Text,
  Menu,
  MenuButton,
  MenuDivider,
  MenuOptionGroup,
  MenuItemOption,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { ExpandMore } from "@material-ui/icons";
import { Link } from "react-router-dom";

import { NAVBAR_HEIGHT } from "@svix/common/widgets/Navbar";

import { routeResolver } from "src/App";
import { getRegionConfig } from "src/constants";
import { useAppDispatch, useAppSelector } from "src/hooks/store";
import { switchEnvironment } from "src/store/auth";
import { useActiveEnv } from "src/store/selectors";
import { getEnvTag } from "src/utils";

export default function EnvSwitcher() {
  const dispatch = useAppDispatch();
  const activeEnv = useActiveEnv();
  const envs = useAppSelector((state) => state.auth.environments);

  if (!activeEnv) {
    return null;
  }

  return (
    <>
      <Menu>
        <MenuButton
          data-cy="environment-menu-button"
          textAlign="left"
          color="white"
          aria-label="show environments"
          height="calc(100% - 12px)"
          borderRadius="md"
          px={3}
          _hover={{ bgColor: "blue.600" }}
          _expanded={{ bgColor: "blue.600" }}
        >
          <Flex alignItems="center">
            <Icon
              display={{ md: "none", lg: "block" }}
              boxSize={8}
              as={getRegionConfig(activeEnv.region).flag}
              mr={4}
            />
            <Box mr={3}>
              <Box>{activeEnv.orgName}</Box>
              <Box fontSize="x-small">{getEnvTag(activeEnv)}</Box>
            </Box>
            <ExpandMore opacity={0.6} />
          </Flex>
        </MenuButton>
        <MenuList maxH={`calc(100vh - ${NAVBAR_HEIGHT} - 12px)`} overflowY="scroll">
          <MenuOptionGroup
            title="Switch Environment"
            type="checkbox"
            value={activeEnv.orgId}
          >
            {/* Limit the number of envs rendered in this list as a mitigation for cases where the user has *a ton* of environments. */}
            {/* A virtualized list is a better solution, but it's not worth the effort here since this (realistically) doesn't happen outside of our staging environments. */}
            {envs.slice(0, 50).map((env) => (
              <MenuItemOption
                key={env.orgId}
                value={env.orgId}
                onClick={async () => switchEnvironment(env, dispatch)}
              >
                {env.orgName}
                <Text variant="caption" fontSize="xs" alignItems="center" display="flex">
                  <Icon boxSize={4} as={getRegionConfig(env.region).flag} mr={2} />
                  {getEnvTag(env)}
                </Text>
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
          <MenuDivider />
          <MenuItem as={Link} to={routeResolver.getRoute("environments")}>
            Manage Environments
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}

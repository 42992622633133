import { useEffect } from "react";
import {
  Box,
  Collapse,
  Heading,
  Link,
  Stack,
  Tab,
  Tabs,
  TabList,
  FormErrorMessage,
  HStack,
  FormLabel,
  FormControl,
  TabPanel,
  TabPanels,
  Text,
  useToast,
  useToken,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import * as yup from "yup";

import * as C from "@svix/common/constants";
import { stripEmptyFields, setErrors } from "@svix/common/formUtils";
import Card from "@svix/common/widgets/Card";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import Select from "@svix/common/widgets/form/Select";
import TextAreaField from "@svix/common/widgets/form/TextArea";
import TextField from "@svix/common/widgets/form/TextField";
import Toggle from "@svix/common/widgets/form/Toggle";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getApiConfiguration } from "src/api";
import { routeResolver } from "src/App";
import {
  CustomColorPalette,
  OrganizationSettingsApi,
  SettingsInternalIn,
} from "src/generated/dashboard-openapi";
import { useAppSelector } from "src/hooks/store";
import { isEE } from "src/utils";
import BorderRadiusPicker from "./BorderRadiusPicker";
import SwatchField from "./Swatch";
import { trackEvent } from "../../../analytics";

const schema = yup.object().shape({
  customFontFamily: yup.string().nullable(),
  customFontFamilyUrl: yup.string().when("customFontFamily", function (v: string) {
    if (v === "Custom") {
      return yup
        .string()
        .trim()
        .required("URL is required when using a Custom font family")
        .nullable();
    }
    return yup.string().nullable();
  }),
  customColor: yup.string().nullable(),
  customLogoUrl: yup.string().nullable(),
  customBaseFontSize: yup
    .number()
    .nullable()
    .moreThan(8, "Font size must be more than 8px")
    .lessThan(24, "Font size must be less than 24px")
    .transform((_, val) => (val !== "" && val !== null ? Number(val) : null)),
  customThemeOverride: yup.object().shape({
    borderRadius: yup.object().shape({
      button: yup.string().nullable(),
      table: yup.string().nullable(),
      card: yup.string().nullable(),
    }),
  }),
  customStringsOverride: yup
    .object()
    .shape({
      channelsOne: yup
        .string()
        .nullable()
        .transform((v) => v?.trim()),
      channelsMany: yup
        .string()
        .nullable()
        .transform((v) => v?.trim()),
      channelsHelp: yup
        .string()
        .nullable()
        .transform((v) => v?.trim()),
    })
    .test("both-or-none", "Both channel fields must be set", (obj: any) => {
      const { channelsOne, channelsMany } = obj;
      return (!channelsOne && !channelsMany) || (channelsOne && channelsMany);
    }),
  showUseSvixPlay: yup.boolean().nullable(),
});

const customFontsOptions = Object.freeze([
  "Helvetica",
  "Roboto",
  "Open Sans",
  "Lato",
  "Source Sans Pro",
  "Raleway",
  "Ubuntu",
  "Inter",
  "Manrope",
  "DM Sans",
  "Poppins",
  "Lexend Deca",
  "Rubik",
  "Custom", // Requires customFontFamilyUrl
]);

function translateErrors(type: string | undefined, msg: string): string {
  if (type === "value_error.color") {
    return "Unrecognized color. Please use a hex color (e.g. #a3bb77)";
  }
  return msg;
}

export interface ColorPaletteConfig {
  id: keyof CustomColorPalette;
  name: string;
  lightColor: string;
  darkColor: string;
  helpText?: string;
}

export default function WhiteLabelSettings() {
  const toast = useToast();
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId);

  const { data: orgSettings } = useQuery(
    ["environments", activeEnvId, "orgSettings"],
    async () => {
      const config = await getApiConfiguration();
      const orgSettingsApi = new OrganizationSettingsApi(config);
      return orgSettingsApi.settingsGetOrganizationSettingsGet();
    }
  );

  const primaryColorconfig: ColorPaletteConfig = {
    id: "primary" as any,
    name: "Primary color",
    lightColor: useToken("colors", "blue.500"),
    darkColor: useToken("colors", "blue.500"),
  };

  const colorPaletteConfig: ColorPaletteConfig[] = useInitialColorPaletteConfig();

  const defaultValues = Object.assign(
    {
      customColor: "",
      customFontFamily: "",
      customLogoUrl: "",
      customBaseFontSize: undefined,
      enforceHttps: false,
      displayName: "",
      showUseSvixPlay: true,
    },
    ...[primaryColorconfig, ...colorPaletteConfig].map((config) => ({
      [`${config.id}Light`]: config.lightColor,
      [`${config.id}Dark`]: config.darkColor,
    }))
  );

  const formCtx = useForm<SettingsInternalIn>({
    resolver: yupResolver(schema),
    defaultValues: orgSettings || defaultValues,
    mode: "onBlur",
  });

  const { reset } = formCtx;
  useEffect(() => {
    reset(orgSettings);
  }, [reset, orgSettings]);

  async function saveSettings(values: SettingsInternalIn) {
    const config = await getApiConfiguration();
    const orgSettingsApi = new OrganizationSettingsApi(config);
    try {
      const settings = await orgSettingsApi.settingsUpdateOrganizationSettingsPut(
        stripEmptyFields<SettingsInternalIn>(values)
      );
      reset(settings);
      toast({ status: "success", title: "Settings saved" });
      trackEvent("Customize App Portal");
    } catch (e) {
      setErrors(formCtx.setError, e.body, translateErrors);
    }
  }

  return (
    <>
      <MetaTitle path={["White Labeling"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("settings")}>
            Settings
          </BreadcrumbItem>
          <BreadcrumbItem>White Labeling</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>

      <Card title="White Labeling" maxW="50em">
        <Text size="md" variant="caption">
          Customize how the{" "}
          <Link target="_blank" href={C.docs.appPortal} color="teal.500">
            Consumer App Portal
          </Link>{" "}
          will look for your users in this environment.
        </Text>
        <WhiteLabelSettingsForm formCtx={formCtx} onSubmit={saveSettings} />
      </Card>
    </>
  );
}

export const useInitialColorPaletteConfig = () => {
  return [
    {
      id: "backgroundPrimary",
      name: "Background",
      lightColor: "#f8f9fd",
      darkColor: useToken("colors", "gray.800"),
    },
    {
      id: "backgroundSecondary",
      name: "Surface Background",
      lightColor: "#FFFFFF",
      darkColor: useToken("colors", "gray.900"),
      helpText: "Background for cards, tables and other surfaces",
    },
    {
      id: "backgroundHover",
      name: "Surface Header",
      lightColor: useToken("colors", "gray.100"),
      darkColor: useToken("colors", "gray.800"),
      helpText: "Background for card headers and table headers",
    },
    {
      id: "interactiveAccent",
      name: "Interactive Accent",
      lightColor: useToken("colors", "blue.500"),
      darkColor: useToken("colors", "blue.400"),
      helpText: "For secondary buttons, links, and other interactive elements",
    },
    {
      id: "navigationAccent",
      name: "Navigation Accent",
      lightColor: useToken("colors", "blue.500"),
      darkColor: useToken("colors", "blue.400"),
      helpText: "For the top-level navigation items",
    },
    {
      id: "buttonPrimary",
      name: "Button Primary",
      lightColor: useToken("colors", "blue.500"),
      darkColor: useToken("colors", "blue.400"),
      helpText: "For the main action buttons",
    },
    {
      id: "textPrimary",
      name: "Text Primary",
      lightColor: useToken("colors", "gray.800"),
      darkColor: "#FFFFFF",
    },
    {
      id: "textDanger",
      name: "Text Danger",
      lightColor: useToken("colors", "red.500"),
      darkColor: useToken("colors", "red.300"),
      helpText: "For error messages and other warnings",
    },
  ] as ColorPaletteConfig[];
};

export const WhiteLabelSettingsForm = ({
  formCtx,
  onSubmit,
  showSubmitButton = true,
}: {
  onSubmit: (values: SettingsInternalIn) => Promise<void>;
  formCtx: ReturnType<typeof useForm>;
  showSubmitButton?: boolean;
}) => {
  const primaryColorconfig: ColorPaletteConfig = {
    id: "primary" as any,
    name: "Primary color",
    lightColor: useToken("colors", "blue.500"),
    darkColor: useToken("colors", "blue.500"),
  };

  const colorPaletteConfig: ColorPaletteConfig[] = useInitialColorPaletteConfig();

  const defaultValues = Object.assign(
    {
      customColor: "",
      customFontFamily: "",
      customLogoUrl: "",
      customBaseFontSize: undefined,
      enforceHttps: false,
      displayName: "",
      showUseSvixPlay: true,
    },
    ...[primaryColorconfig, ...colorPaletteConfig].map((config) => ({
      [`${config.id}Light`]: config.lightColor,
      [`${config.id}Dark`]: config.darkColor,
    }))
  );

  const { watch, formState } = formCtx;

  return (
    <Form onSubmit={onSubmit} {...formCtx}>
      <Stack spacing={4} mt={4} width="100%">
        <TextField
          maxW="24em"
          control={formCtx.control}
          name="displayName"
          label="Display Name"
          placeholder="My Company"
          helperText={
            <>
              The name of your company or service. Visible to users in the App Portal and
              the{" "}
              <Link color="teal.500" href={C.docs.advanced.eventCatalog}>
                Event Catalog
              </Link>
              .
            </>
          }
        />
        <TextField
          maxW="24em"
          control={formCtx.control}
          name="customLogoUrl"
          type="url"
          label="Icon URL"
          placeholder="https://www.example.com/static/logo.png"
          helperText={
            <>
              Used in the standalone App Portal experience. Not visible in the{" "}
              <Link color="teal.500" href={C.docs.appPortal} isExternal>
                embedded App Portal
              </Link>
              .
            </>
          }
        />
        <Select
          maxW="24em"
          label="Custom Font"
          control={formCtx.control}
          name="customFontFamily"
          helpText={
            watch("customFontFamily") !== "Custom" && (
              <>You can also set a custom font by providing a URL to a font file. </>
            )
          }
        >
          <option value="">Default</option>
          {customFontsOptions.map((fontOption) => (
            <option value={fontOption} key={fontOption}>
              {fontOption === "Custom" ? "Custom (use external font URL)" : fontOption}
            </option>
          ))}
        </Select>
        <Collapse in={watch("customFontFamily") === "Custom"}>
          <TextField
            maxW="40em"
            control={formCtx.control}
            name="customFontFamilyUrl"
            label="Custom Font URL"
            type="url"
            placeholder="https://fonts.com/myfont.woff2"
            helperText="URL of a woff2 font file (e.g. https://fonts.gstatic.com/s/librebaskerville.woff2)"
          />
        </Collapse>
        <TextField
          maxW="24em"
          control={formCtx.control}
          name="customBaseFontSize"
          label="Base Font Size (in pixels)"
          placeholder="e.g. 16"
          helperText="This affects all text size on the screen relative to the size of the text in the main body of the page. Default: 16px"
          type="number"
        />
        {isEE && (
          <Toggle
            label="Show 'Use Svix Play'"
            control={formCtx.control}
            name="showUseSvixPlay"
            helpText={
              <>
                Show the "Use Svix Play" button when creating an endpoint in the App
                Portal.
              </>
            }
          />
        )}
        <Heading pt={2} size="sm">
          Color Palette
        </Heading>
        <Tabs colorScheme="brand" lazyBehavior="keepMounted">
          <TabList>
            <Tab>Light Theme</Tab>
            <Tab>Dark Theme</Tab>
          </TabList>
          <TabPanels>
            {["Light", "Dark"].map((colorMode) => (
              <TabPanel key={colorMode}>
                <Box
                  display="grid"
                  gridTemplateColumns="1fr 1fr 1fr"
                  gridColumnGap={4}
                  gridRowGap={4}
                  maxW="40em"
                >
                  <Box gridColumn="1/3">
                    <SwatchField
                      control={formCtx.control}
                      name={`colorPalette${colorMode}.${primaryColorconfig.id}`}
                      label={primaryColorconfig.name}
                      key={`colorPalette${colorMode}.${primaryColorconfig.id}`}
                      placeholder={defaultValues[`${primaryColorconfig.id}${colorMode}`]}
                      helpText={primaryColorconfig.helpText}
                    />
                  </Box>
                  <Box gridColumn="span 1" />
                  {colorPaletteConfig.map((palette) => (
                    <Box
                      gridColumn={{ base: "span 2", md: "span 1" }}
                      key={`colorPalette${colorMode}.${palette.id}`}
                    >
                      <SwatchField
                        control={formCtx.control}
                        name={`colorPalette${colorMode}.${palette.id}`}
                        label={palette.name}
                        placeholder={defaultValues[`${palette.id}${colorMode}`]}
                        helpText={palette.helpText}
                      />
                    </Box>
                  ))}
                </Box>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
        <Heading pt={2} size="sm">
          Borders
        </Heading>
        <Box
          display="grid"
          gridTemplateColumns="1fr 1fr 1fr"
          gridColumnGap={8}
          gridRowGap={4}
          maxW="40em"
        >
          <BorderRadiusPicker
            name="customThemeOverride.borderRadius.button"
            label="Button corners"
            formCtx={formCtx}
          />
          <BorderRadiusPicker
            name="customThemeOverride.borderRadius.input"
            label="Input corners"
            formCtx={formCtx}
          />
          <BorderRadiusPicker
            name="customThemeOverride.borderRadius.card"
            label="Card corners"
            formCtx={formCtx}
          />
        </Box>
        <Accordion allowToggle borderTopWidth={0} my={4} w="100%">
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Heading size="sm" as="div" flex="1" textAlign="left">
                  Advanced settings
                </Heading>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Heading size="sm" pb={2}>
                Content
              </Heading>
              <FormControl isInvalid={formState.errors["customStringsOverride"]}>
                <FormLabel mt={2} mb={0}>
                  Channels
                </FormLabel>
                <Text variant="caption" mb={2}>
                  Rename 'channels' in the App Portal, depending on the usage you give
                  them in your application.
                </Text>
                <HStack maxW="32em">
                  <TextField
                    control={formCtx.control}
                    name="customStringsOverride.channelsOne"
                    placeholder="Channel"
                    helperText="Singular form."
                  />
                  <TextField
                    control={formCtx.control}
                    name="customStringsOverride.channelsMany"
                    placeholder="Channels"
                    helperText="Plural form."
                  />
                </HStack>
                <TextAreaField
                  mt={3}
                  maxW="28em"
                  control={formCtx.control}
                  name="customStringsOverride.channelsHelp"
                  placeholder="Channels are an extra dimension of filtering messages orthogonal to event types. They are case-sensitive and only messages with the corresponding channel will be sent to this endpoint."
                  helperText="Channels help text."
                />
                {formState.errors["customStringsOverride"]?.message && (
                  <FormErrorMessage>
                    {formState.errors["customStringsOverride"].message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <GeneralFormErrors />
      </Stack>
      {showSubmitButton && (
        <Box mt={4}>
          <SubmitButton
            mt={4}
            type="submit"
            variant="solid"
            loadingText="Saving"
            showLoading
          >
            Save
          </SubmitButton>
        </Box>
      )}
    </Form>
  );
};

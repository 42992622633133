import { HStack, Box, Text, IconButton } from "@chakra-ui/react";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";

import useCopyToClipboard from "@svix/common/hooks/copyToClipboard";
import Card from "@svix/common/widgets/Card";
import Mono from "@svix/common/widgets/Mono";

export default function SourceUrl({ ingestUrl }: { ingestUrl: string }) {
  const [_, copyToClipboard] = useCopyToClipboard(ingestUrl);

  return (
    <Card>
      <HStack gap={4} justifyContent="space-between">
        <Box>
          <Text fontSize="sm" fontWeight="semibold" mb={1}>
            Ingest URL
          </Text>
          <Mono fontSize="sm">{ingestUrl}</Mono>
        </Box>
        <IconButton
          aria-label="Copy to clipboard"
          color="text.muted"
          icon={<FileCopyOutlinedIcon style={{ height: "16px", width: "16px" }} />}
          onClick={() => copyToClipboard()}
          size="md"
          variant="outline"
        >
          Copy to clipboard
        </IconButton>
      </HStack>
    </Card>
  );
}

import { useQuery } from "react-query";
import { useParams } from "react-router";
import { TransformationTemplateApi } from "svix/dist/openapi";

import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  BreadcrumbItem,
  Breadcrumbs,
  PageToolbar,
} from "@svix/common/widgets/PageToolbar";

import { getSvix } from "src/api";
import { routeResolver } from "src/App";
import { useAppSelector } from "src/hooks/store";
import { ConnectorForm } from "./ConnectorForm";

export function ConnectorUpdateScreen() {
  const activeEnvId = useAppSelector((store) => store.auth.activeEnvId)!;
  const { id: templateId } = useParams<{ id: string }>();
  const { data: template } = useQuery(
    ["environments", activeEnvId, "connectors", templateId],
    async () => {
      const svix = await getSvix();
      const api = new TransformationTemplateApi(svix._configuration);
      return await api.v1TransformationTemplateGet({
        transformationTemplateId: templateId,
      });
    }
  );

  return (
    <>
      <MetaTitle path={["Edit connector"]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem to={routeResolver.getRoute("connectors")}>
            Connectors
          </BreadcrumbItem>
          <BreadcrumbItem>Edit</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>

      {template && <ConnectorForm isUpdate template={template} />}
    </>
  );
}
